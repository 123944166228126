import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ActionDelete from "@material-ui/icons/Delete";
import classnames from "classnames";
import { crudDelete, startUndoable, translate } from "ra-core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { Button } from "react-admin";
import { Box, Typography } from "@material-ui/core";

const styles = (theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textAlign: "center",
    padding: "32px",
  },
  content: {
    padding: "32",
  },
  deleteButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
});

class DeleteButtonWithConfirmation extends Component {
  state = {
    showDialog: false,
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ showDialog: false });
    const {
      dispatchCrudDelete,
      startUndoable,
      resource,
      record,
      basePath,
      redirect,
      undoable,
    } = this.props;
    if (undoable) {
      startUndoable(
        crudDelete(resource, record.id, record, basePath, redirect)
      );
    } else {
      dispatchCrudDelete(resource, record.id, record, basePath, redirect);
    }
  };

  render() {
    const { showDialog } = this.state;
    const { label = "ra.action.delete", classes = {}, className } = this.props;
    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          label={label}
          className={classnames(
            "ra-delete-button",
            classes.deleteButton,
            className
          )}
          key="button"
        >
          <ActionDelete />
        </Button>
        <Dialog
          fullWidth
          maxWidth={"xs"}
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Are you sure?"
        >
          <DialogTitle className={classes.title}>
            Delete {this.props.entity}?
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Box p={4}>
              <Typography variant={"body1"}>
                <span style={{ fontWeight: "bold" }}>Warning</span>, you are
                about to delete 1 {this.props.entity}.
              </Typography>
              <Typography variant={"body1"}>
                <span style={{ fontWeight: "bold" }}>
                  You won't be able to undo this action.
                </span>
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick} />
            <Button
              onClick={this.handleDelete}
              label={"I'm Sure"}
              className={classnames(
                "ra-delete-button",
                // classes.deleteButton,
                className
              )}
              key="button"
            />
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DeleteButtonWithConfirmation.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  dispatchCrudDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
  entity: PropTypes.string,
};

DeleteButtonWithConfirmation.defaultProps = {
  redirect: "list",
  undoable: true,
};

export default compose(
  connect(null, { startUndoable, dispatchCrudDelete: crudDelete }),
  translate,
  withStyles(styles)
)(DeleteButtonWithConfirmation);
