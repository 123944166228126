import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  bannerPlaceholder: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  title: {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: 28,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 20,
    color: "#fff",
    opacity: 0.5,
  },
}));

function Placeholder({ type }) {
  const classes = useStyles();
  return (
    <Box className={classes.bannerPlaceholder}>
      <Typography variant="h6" className={classes.title}>
        Freddie
      </Typography>
      <Typography variant={"h5"} className={classes.subtitle}>
        {type}
      </Typography>
    </Box>
  );
}

export default Placeholder;
