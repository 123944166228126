import {
  List,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EmailField,
  TextInput,
  EditButton,
  Filter,
  BooleanInput
} from "react-admin";
import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomizableDatagrid from "ra-customizable-datagrid";
import { BooleanField } from "../shared/BooleanField";
import React from "react";

const useStyles = makeStyles({
  field: { width: "calc(50% - 15px)" },
  root: { padding: "15px 30px" }
});

const HomeownerForm = () => {
  const classes = useStyles();
  return (
    <Container
      classes={{
        root: classes.root
      }}
      maxWidth="lg"
    >
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        spacing={2}
      >
        <TextInput
          className={classes.field}
          variant="outlined"
          source="firstName"
        />
        <TextInput
          className={classes.field}
          variant="outlined"
          source="lastName"
        />
      </Grid>
      <TextInput fullWidth variant="outlined" source="email" />
      <BooleanInput fullWidth variant="outlined" source="optInNewsletter" />
    </Container>
  );
};

const HomeownerFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
  </Filter>
);

export const HomeownerList = (props) => (
  <List filters={<HomeownerFilter />} {...props}>
    <CustomizableDatagrid>
      <BooleanField source="optInNewsletter" />
      <TextField source="firstName" />
      <EmailField source="lastName" />
      <TextField source="email" />
      <EditButton basePath="/manage/home-owners" />
    </CustomizableDatagrid>
  </List>
);

export const HomeownerEdit = (props) => (
  <Edit title="Edit a Homeowner" {...props}>
    <SimpleForm>
      <HomeownerForm />
    </SimpleForm>
  </Edit>
);

export const HomeownerCreate = (props) => (
  <Create title="Create a Homeowner" {...props}>
    <SimpleForm>
      <HomeownerForm />
    </SimpleForm>
  </Create>
);
