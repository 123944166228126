import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import RESOURCES from "../boilerplate/resources";
import { makeStyles } from "@material-ui/core/styles";
import { Bar } from "react-chartjs-2";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import moment from "moment";
import { fetchData } from "../utils";
import configuration from "../config";

const useStyles = makeStyles({
  root: {
    minWidth: 180,
    width: "100%",
  },
  title: {
    fontSize: 14,
  },
  onlineCard: {
    minHeight: 100,
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  bottomCard: {
    minHeight: 100,
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "flex-start",
  },
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  periodFilter: {
    display: "flex",
    justifyContent: "flex-end"
  },
});

const CardsOnTop = React.memo((props) => {
  const { classes, onlineUsers } = props;

  return (
    <>
      <Grid item className={classes.onlineCard} md={4} xs={12}>
        <CardOnTop
          cardTitle={"Users"}
          cardData={onlineUsers.users}
          classes={classes}
        />
      </Grid>
      <Grid item className={classes.onlineCard} md={4} xs={12}>
        <CardOnTop
          cardTitle={"Designers"}
          cardData={onlineUsers.designers}
          classes={classes}
        />
      </Grid>
      <Grid item className={classes.onlineCard} md={4} xs={12}>
        <CardOnTop
          cardTitle={"Home Owners"}
          cardData={onlineUsers.homeOwners}
          classes={classes}
        />
      </Grid>
    </>
  );
});

const CardOnTop = React.memo((props) => {
  const { cardTitle, cardData, classes } = props;
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Online {cardTitle}
        </Typography>
        <Typography variant="h5" component="h2">
          {cardData}
        </Typography>
      </CardContent>
    </Card>
  );
});

const CardOnBottom = React.memo((props) => {
  const { cardTitle, cardData, classes } = props;

  const getSchema = (data, title) => {
    switch (title) {
      case "Designers":
        return (
          <Grid container direction={"column"}>
            {data?.map((designer) => {
              return (
                <Grid item key={designer.id}>
                  <Link
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: 14,
                    }}
                    href={`/#/manage/designers/${designer.id}/show/`}
                  >
                    {designer.firstName} {designer.lastName}
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        );
      case "Inspirations":
        return (
          <Grid container direction={"column"}>
            {data?.map((inspiration) => {
              return (
                <Grid item key={inspiration.id}>
                  <Link
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: 14,
                    }}
                    href={`/#/manage/inspirations/${inspiration.id}`}
                  >
                    {inspiration.slug}
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        );
      case "Articles":
        return (
          <Grid container direction={"column"}>
            {data?.map((article) => {
              return (
                <Grid item key={article.id}>
                  <Link
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: 14,
                    }}
                    href={`/#/manage/articles/${article.id}`}
                  >
                    {article.title}
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          List with Top {cardTitle}
        </Typography>
        <Typography variant="h5" component="h2">
          {getSchema(cardData, cardTitle)}
        </Typography>
      </CardContent>
    </Card>
  );
});

const { api } = configuration.endpoints;

const VisitsBarChart = React.memo((props) => {
  const { data } = props;
  const [visits, setVisits] = useState([]);
  const [labels, setLabels] = useState([]);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    setVisits(data.map((d) => d.totalActivities));
    setLabels(data.map((d) => d.label));
  }, [data]);

  useEffect(() => {
    if (visits?.length > 0 && labels?.length > 0) {
      setChartData({
        labels: labels,
        datasets: [
          {
            data: visits,
            label: "Visits",
            backgroundColor: ["#587cac"],
            borderColor: ["#587cac"],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [visits, labels]);

  const config = {
    legend: {
      display: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        labels: labels,
        legend: {
          display: true,
        },
      },
    },
  };

  return (
    <div>
      <Bar
        type={"bar"}
        data={chartData}
        options={{
          legend: {
            display: false,
          },
          ...config,
        }}
      />
    </div>
  );
});

const choices = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

const TimelineActivity = React.memo((props) => {
  const { stream } = props;

  return (
    <Card
      style={
        {
          // backgroundColor: "white",
          // borderRadius: 10,
          // boxShadow: "#1513131c 1px 5px 20px 0px",
          // position: "relative",
        }
      }
    >
      <Typography variant={"h5"} align={"center"}>
        Latest Activity
      </Typography>
      <Timeline align="right">
        {stream.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography color="textSecondary" style={{ fontSize: 10 }}>
                    {item.userInfo}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography style={{ fontSize: 10 }}>{item.ip}</Typography>
                  <Typography style={{ fontSize: 8 }}>
                    {moment(item.createdAt).format("MM/DD/YYYY HH:mm")}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </React.Fragment>
          );
        })}
      </Timeline>
    </Card>
  );
});

const DashboardWidget = () => {
  const classes = useStyles();

  const [onlineUsers, setOnlineUsers] = useState({
    users: 0,
    designers: 0,
    homeOwners: 0,
  });
  const [topSearch, setTopSearch] = useState({});
  const [activityStatistics, setActivityStatistics] = useState([]);
  const [activityStream, setActivityStream] = useState([]);
  const [period, setPeriod] = useState("day");

  const onlineUsersInterval = useRef();

  const getUsersOnline = useCallback(() => {
    const url = `${api}/${RESOURCES.onlineUsers}`;
    fetchData(url)
      .then((data) => setOnlineUsers(data))
      .catch((ex) => console.log(ex));
  }, []);

  useEffect(() => {
    getUsersOnline();
    onlineUsersInterval.current = setInterval(() => {
      getUsersOnline();
    }, 5000);

    return () => {
      clearInterval(onlineUsersInterval.current);
    };
  }, [getUsersOnline]);

  useEffect(() => {
    const topSearchUrl = `${api}/${RESOURCES.topSearched}?period=${
      period === "day" ? "all" : period
    }`;
    fetchData(topSearchUrl)
      .then((data) => setTopSearch(data))
      .catch((ex) => console.log(ex));

    const activityStatisticsUrl = `${api}/${RESOURCES.activityStatistics}?period=${period}`;
    fetchData(activityStatisticsUrl)
      .then((data) => setActivityStatistics(data))
      .catch((ex) => console.log(ex));

    const activityStreamUrl = `${api}/${RESOURCES.activityStream}`;
    fetchData(activityStreamUrl)
      .then((data) => setActivityStream(data.content))
      .catch((ex) => console.log(ex));
  }, [period]);

  return (
    <div className={classes.root} style={{ marginTop: 8 }}>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        alignContent={"center"}
        spacing={2}
      >
        {/* Online Cards*/}
        <Grid item xs={9}>
          <Grid item container spacing={2}>
            <CardsOnTop classes={classes} onlineUsers={onlineUsers} />
          </Grid>
          {/*Period Filter*/}
          <Box mt={2}/>
          <Grid item className={classes.periodFilter}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              {choices.map((choice) => (
                <Button
                  key={choice.value}
                  onClick={() => setPeriod(choice.value)}
                  variant={period === choice.value ? "contained" : "outlined"}
                >
                  {choice.label}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          <Grid item container className={classes.chartContainer}>
            <Grid item xs={12}>
              <VisitsBarChart data={activityStatistics} />
            </Grid>
          </Grid>
          <Box mt={2}/>
          <Grid item container spacing={2}>
            <Grid item className={classes.bottomCard} md={4} xs={12}>
              <CardOnBottom
                cardTitle={"Designers"}
                cardData={topSearch?.topDesigners}
                classes={classes}
              />
            </Grid>
            <Grid item className={classes.bottomCard} md={4} xs={12}>
              <CardOnBottom
                cardTitle={"Inspirations"}
                cardData={topSearch?.topInspirations}
                classes={classes}
              />
            </Grid>
            <Grid item className={classes.bottomCard} md={4} xs={12}>
              <CardOnBottom
                cardTitle={"Articles"}
                cardData={topSearch?.topArticles}
                classes={classes}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <TimelineActivity stream={activityStream} />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardWidget;
