import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import configuration from "../config";
import {getJwt} from "../utils";

export const httpClient = (url, options = {}, type = "json") => {
  if (!options.headers) {
    if (type === "json") {
      // eslint-disable-next-line
      options.headers = new Headers({ Accept: "application/json" });
    } else {
      // eslint-disable-next-line
      options.headers = new Headers({
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      });
    }
  }
  options.headers.set("Authorization",  getJwt());
  return fetchUtils.fetchJson(url, options);
};

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

const dataProvider = {
  create: (resource, params) => {
    const {
      endpoints: { api },
    } = configuration;

    if (resource === "manage/media") {
      const {
        endpoints: { api },
      } = configuration;

      const formData = new FormData();
      formData.append(
        "data",
        new Blob([JSON.stringify({ slug: params?.data?.slug })], {
          type: "application/json",
        })
      );
      formData.append("file", params?.data?.pictures[0]);

      return httpClient(`${api}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params?.data, id: json?.fileId },
      }));
    }

    if (resource === "manage/designers") {
      const formData = new FormData();
      if (params.newProfileImage) {
        formData.append("profile", params.newProfileImage);
      }
      if (params.newFeatureImage) {
        formData.append("feature", params.newFeatureImage);
      }

      params.portfolioImages.forEach((file) => {
        formData.append("portfolio", file);
      });

      formData.append(
        "user",
        new Blob(
          [
            JSON.stringify({
              ...params?.data
            }),
          ],
          {
            type: "application/json",
          }
        )
      );

      return httpClient(`${api}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params?.data, id: json?.id },
      }));
    }

    if (resource === "manage/articles") {
      const formData = new FormData();

      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              slug: params?.data?.slug,
              title: params?.data?.title,
              body: params?.data?.body,
              categoryIds: params?.data?.categoryIds,
              summary: params?.data?.summary,
              authors: [
                "d641c507-ee6d-4ffe-959c-019e635e8629",
                "53080ef-0513-481c-9da6-c866d6524249",
              ],
              tags: ["sports", "news"],
            }),
          ],
          {
            type: "application/json",
          }
        )
      );

      if (params.newCover) {
        formData.append("file", params.newCover);
      }

      return httpClient(`${api}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params?.data, id: json?.id },
      }));
    }

    if (resource === "manage/inspirations") {
      const formData = new FormData();

      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              slug: params?.data?.slug,
              title: params?.data?.title,
              tags: ["sports", "news"],
            }),
          ],
          {
            type: "application/json",
          }
        )
      );

      formData.append("file", params?.data?.imageId?.rawFile);

      return httpClient(`${api}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params?.data, id: json?.id },
      }));
    }

    if (resource === "manage/design-styles") {
      const formData = new FormData();

      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              id: getRandomArbitrary(0, 100000000),
              name: params?.data?.name,
              order: params?.data?.order,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );

      formData.append("file", params?.data?.imageId?.rawFile);

      return httpClient(`${api}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params?.data, id: json?.id },
      }));
    }

    return httpClient(`${api}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params?.data),
    }).then(({ json }) => ({
      data: { ...params?.data, id: json?.id },
    }));
  },
  update: (resource, params) => {
    const {
      endpoints: { api },
    } = configuration;

    if (resource === "manage/articles") {
      const formData = new FormData();

      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              id: params?.data?.id,
              slug: params?.data?.slug,
              title: params?.data?.title,
              summary: params?.data?.summary,
              body: params?.data?.body,
              categoryIds: params?.data?.categoryIds,
              authors: [
                "d641c507-ee6d-4ffe-959c-019e635e8629",
                "53080ef-0513-481c-9da6-c866d6524249",
              ],
              tags: ["sports", "news"],
            }),
          ],
          {
            type: "application/json",
          }
        )
      );

      console.log(params);
      if (params.newCover) {
        formData.append("file", params.newCover);
      }

      return httpClient(`${api}/${resource}/${params.data.id}`, {
        method: "PUT",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params?.data, id: json?.id },
      }));
    } else if (resource === "/manage/articles/publish") {
      return httpClient(`${api}/manage/articles/${params.id}/publish`, {
        method: "PUT",
        body: {},
      }).then(({ json }) => ({
        data: { ...params?.data, id: json?.id },
      }));
    }

    if (resource === "manage/inspirations") {
      const formData = new FormData();

      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              id: params?.data?.id,
              slug: params?.data?.slug,
              title: params?.data?.title,
              tags: ["sports", "news"],
            }),
          ],
          {
            type: "application/json",
          }
        )
      );

      formData.append("file", params?.data?.imageId?.rawFile);

      return httpClient(`${api}/${resource}/${params.id}`, {
        method: "PUT",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params?.data, id: json?.id },
      }));
    }

    if (resource === "manage/design-styles") {
      const formData = new FormData();

      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              id: params?.data?.id,
              name: params?.data?.name,
              order: params?.data?.order,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );

      formData.append("file", params?.data?.imageId?.rawFile);

      return httpClient(`${api}/${resource}/${params.id}`, {
        method: "PUT",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params?.data, id: json?.id },
      }));
    }

    if (resource === "manage/designers") {
      const formData = new FormData();
      if (params.newProfileImage) {
        formData.append("profile", params.newProfileImage);
      }
      if (params.newFeatureImage) {
        formData.append("feature", params.newFeatureImage);
      }

      const portfolioImages = params.portfolioImageIds.map((imageId) => ({ imageId }))
      params.portfolioImages.forEach((file, index) => {
        const key = `portfolio_${index}`;
        formData.append(key, file);
        portfolioImages.push({ imageId: key });
      });

      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              ...params?.data,
              portfolioImages,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );

      return httpClient(`${api}/${resource}/${params.data.id}`, {
        method: "PUT",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params?.data, id: json?.id },
      }));
    }

    return httpClient(`${api}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: { ...params?.data, id: 1 } }));
  },
  delete: (resource, params) => {
    const {
      endpoints: { api },
    } = configuration;

    if (resource === "manage/media") {
      return httpClient(`${api}/${resource}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => window.location.reload());
    }

    return httpClient(`${api}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: { ...params?.data, id: 1 } }));
  },
  getOne: (resource, params) => {
    const {
      endpoints: { api },
    } = configuration;

    return httpClient(`${api}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    }));
  },
  getList: (resource, params) => {
    const {
      endpoints: { api },
    } = configuration;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      page,
      sort: field,
      order,
      items: perPage,
      term: params?.filter?.q || "%%",
    };

    if (resource === "manage/designer-messages") {
      const status = params.filter.status || "pending";
      const url = `${api}/${resource}/${status}?${stringify(query)}`;

      return httpClient(url).then(({ json }) => ({
        data: json.content,
        total: json.totalElements,
      }));
    }

    if (resource === "manage/designers") {
      const status = params.filter.status || "pending";
      let q = query
      // console.log(q)
      switch (query.sort) {
        case "createdAt":
          q.sort = "date";
          break;
        case "acceptedAt":
          q.sort = "acceptancedate";
          break;
        default: break
      }
      const url = `${api}/${resource}/${status}?${stringify(q)}`;

      return httpClient(url).then(({ json }) => ({
        data: json.content,
        total: json.totalElements,
      }));
    }

    if (resource === "manage/media") {
      const url = `${api}/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ json }) => ({
        data: json?.map(({ fileId, fileName, slug }) => ({
          id: fileId,
          fileName,
          slug,
        })),
        total: 1,
      }));
    }

    const uri = `${api}/${resource}?${stringify(query)}`;

    return httpClient(uri).then(({ json }) => ({
      data: json.content,
      total: json.totalElements,
    }));
  },
};

export default dataProvider;
