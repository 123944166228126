import React, { useEffect, useState } from "react";
import { Admin, Resource } from "react-admin";
import { createMuiTheme } from "@material-ui/core/styles";
import CopyrightIcon from "@material-ui/icons/Copyright";
import DescriptionIcon from "@material-ui/icons/Description";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PostAddIcon from "@material-ui/icons/PostAdd";
import BlurCircularIcon from "@material-ui/icons/BlurCircular";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import EcoIcon from "@material-ui/icons/Eco";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import {
  DesignerList,
  DesignerEdit,
  DesignerShow,
  DesignerCreate,
} from "../pages/Designer/Designer";
import { HomeownerList, HomeownerEdit } from "../pages/Homeowner";
import { UserList, UserCreate, UserEdit } from "../pages/User";
import { ArticleList, ArticleCreate, ArticleEdit } from "../pages/Article";
import { TagList, TagCreate, TagEdit } from "../pages/Tag";
import {
  InspirationList,
  InspirationCreate,
  InspirationEdit,
} from "../pages/Inspiration";
import { RoomList, RoomCreate, RoomEdit } from "../pages/Room";
import { MediaList } from "../pages/Media";
import { PageList, PageCreate, PageEdit } from "../pages/Page";
import {
  DesignStyleList,
  DesignStyleCreate,
  DesignStyleEdit,
} from "../pages/DesignStyle";
import { GlossaryList, GlossaryCreate, GlossaryEdit } from "../pages/Glossary";
import {
  DesignerMessageList,
  DesignerMessageEdit,
} from "../pages/DesignerMessage";
import RESOURCES from "./resources";
import authProvider from "../providers/authProvider";
import dataProvider from "../providers/dataProvider";
import Login from "../pages/Login";
import Layout from "../shared/Layout";
import configuration from "../config";
import Dashboard from "../pages/Dashboard";
import RoomIcon from "@material-ui/icons/Room";
import { fetchData } from "../utils";
const { theme } = configuration;

const customTheme = createMuiTheme(theme);

function fl(label, count) {
  if (count) return `${label} (+${count})`;
  return label;
}

const MarmelabAdmin = () => {
  const { designers, messages } = useCounts();
  return (
    <Admin
      loginPage={Login}
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      theme={customTheme}
      layout={Layout}
    >
      <Resource
        icon={EcoIcon}
        options={{ label: fl("Designers", designers) }}
        name={RESOURCES.designers}
        list={DesignerList}
        create={DesignerCreate}
        edit={DesignerEdit}
        show={DesignerShow}
      />
      <Resource
        icon={QuestionAnswerIcon}
        options={{ label: fl("Designer messages", messages) }}
        name={RESOURCES.designerMessages}
        list={DesignerMessageList}
        edit={DesignerMessageEdit}
      />
      <Resource
        icon={AccountBoxIcon}
        options={{ label: "Homeowners" }}
        name={RESOURCES.homeowners}
        list={HomeownerList}
        edit={HomeownerEdit}
      />
      <Resource
        icon={PostAddIcon}
        options={{ label: "Articles" }}
        name={RESOURCES.articles}
        list={ArticleList}
        create={ArticleCreate}
        edit={ArticleEdit}
      />
      <Resource
        icon={DescriptionIcon}
        options={{ label: "Pages" }}
        name={RESOURCES.pages}
        list={PageList}
        create={PageCreate}
        edit={PageEdit}
      />
      <Resource
        icon={BlurCircularIcon}
        options={{ label: "Inspirations" }}
        name={RESOURCES.inspirations}
        list={InspirationList}
        create={InspirationCreate}
        edit={InspirationEdit}
      />
      <Resource
        icon={BubbleChartIcon}
        options={{ label: "Design styles" }}
        name={RESOURCES.designStyles}
        list={DesignStyleList}
        create={DesignStyleCreate}
        edit={DesignStyleEdit}
      />
      <Resource
        icon={CopyrightIcon}
        options={{ label: "Glossary" }}
        name={RESOURCES.glossary}
        list={GlossaryList}
        create={GlossaryCreate}
        edit={GlossaryEdit}
      />
      <Resource
        icon={LocalOfferIcon}
        options={{ label: "Tags" }}
        name={RESOURCES.tags}
        list={TagList}
        create={TagCreate}
        edit={TagEdit}
      />
      <Resource
        icon={PermMediaIcon}
        options={{ label: "Media" }}
        name={RESOURCES.media}
        list={MediaList}
      />
      <Resource
        icon={SupervisorAccountIcon}
        options={{ label: "Users" }}
        name={RESOURCES.users}
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
      />

      <Resource
        icon={RoomIcon}
        options={{ label: "Rooms" }}
        name={RESOURCES.rooms}
        create={RoomCreate}
        edit={RoomEdit}
        list={RoomList}
      />

      {/*<Resource*/}
      {/*  icon={RoomIcon}*/}
      {/*  options={{ label: "Categories" }}*/}
      {/*  name={RESOURCES.categories}*/}
      {/*  create={CategoryCreate}*/}
      {/*  edit={CategoryEdit}*/}
      {/*  list={CategoryList}*/}
      {/*/>*/}

      <Resource name={RESOURCES.registerDesigner} />
    </Admin>
  );
};

export default MarmelabAdmin;

// Counts api
const { api } = configuration.endpoints;
const useCounts = () => {
  const [counts, setCounts] = useState({
    designers: 0,
    messages: 0,
    inspirations: 0,
  });

  useEffect(() => {
    fetchData(api + "/manage/pending")
      .then((data) => setCounts(data))
      .catch((ex) => console.log(ex));
  }, []);

  return counts;
};
