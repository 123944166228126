import { AppBar as RaAppBar } from "react-admin";
import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import configuration from "../config";

const useStyles = makeStyles({
  link: {
    color: "white",
    fontSize: 16,
    letterSpacing: "0.14px",
    marginRight: 40,
    marginBottom: "-11px",
    marginTop: "4px",
  },
  wrapper: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  active: {
    borderBottom: "4px solid #fff",
  },
  root: {
    padding: "0px 48px 0 25px",
  },
  appBar: {
    padding: "0 0 0 20px",
  },
  logo: {
    color: "#fff",
    fontSize: "22px",
    fontWeight: "600",
    textDecoration: "none",
    letterSpacing: 1,
  },
  preview: {
    color: "#fff",
    fontSize: "13px",
    marginLeft: 16,
  },
});
const AppBar = (props) => {
  const classes = useStyles();

  return (
    <RaAppBar
      position="relative"
      className={classes.appBar}
      open={false}
      {...props}
    >
      <div className={classes.wrapper}>
        <div className={classes.logo}>FREDDIE</div>
        <Button
          size={"small"}
          className={classes.preview}
          href={configuration.endpoints.site}
        >
          View Site
        </Button>
      </div>
    </RaAppBar>
  );
};

export default AppBar;
