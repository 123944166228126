import { Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
  title: {
    marginTop: -33,
    backgroundColor: "#fff",
    width: "max-content",
    margin: "auto",
    padding: 16,
    marginBottom: 16,
    fontSize: 24
  }
});

export const Title = ({ title, ...rest }) => {
  const classes = useStyles();
  return (
    <div {...rest}>
      <Divider style={{ backgroundColor: "#000" }} />
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};
