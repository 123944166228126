import { useState } from "react";
import { useLogin, useNotify, Notification, defaultTheme } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { Card, TextField, Button } from "@material-ui/core";
import Logo from "../freddie.svg";
import React from "react";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(https://source.unsplash.com/random/1600x900)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  card: {
    minWidth: 400,
    marginTop: "10em",
    padding: "15px 5px",
    borderRadius: "10px"
  },
  avatar: {
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  icon: {
    backgroundColor: theme.palette.secondary.main
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500]
  },
  form: {
    padding: "2em",
    display: "flex",
    flexDirection: "column"
  },
  input: {
    marginBottom: "15px"
  },
  submit: {
    width: "100%",
    height: "50px",
    marginTop: 10,
    background: "#3A5273"
  },
  actions: {
    padding: "0 1em 1em 1em"
  }
}));

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const classes = useStyles();
  const submit = (e) => {
    e.preventDefault();
    login({ email, password }).catch(() =>
      notify("Invalid email or password")
    );
  };

  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <form onSubmit={submit} noValidate>
        <div className={classes.main}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <img
                alt="logo"
                style={{ alignSelf: "center", width: 120 }}
                src={Logo}
              />
              <br />
            </div>
            <div className={classes.form}>
              <TextField
                name="email"
                className={classes.input}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="outlined-basic"
                label="Email"
                variant="outlined"
              />

              <TextField
                name="password"
                className={classes.input}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="outlined-basic"
                label="Password"
                variant="outlined"
              />

              <Button
                className={classes.submit}
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </Card>
          <Notification />
        </div>
      </form>
      <Notification />
    </ThemeProvider>
  );
};

export default Login;
