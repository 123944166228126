import axios from "axios";
import configuration from "./config";

export function getFileUrl(uuid, type = "files") {
  const {api} = configuration.endpoints;
  return `${api}/${type}/${uuid}`
}

axios.defaults.headers.common = {
  Authorization: getJwt(),
};

export function getDefaultHeaders() {
  return {
    Authorization: getJwt()
  };
}

export function getAxiosConfig() {
  return {
    headers: getDefaultHeaders(),
  };
}
export function getCredentials() {
  const permUserAuth = localStorage.getItem("authUser");
  const tempUserAuth = sessionStorage.getItem("authUser");
  return tempUserAuth || permUserAuth
}

export function getToken() {
  const credentials = getCredentials()
  return credentials ? JSON.parse(credentials).access_token : null;
}

export function getJwt() {
  const token = getToken()
  return token ? `Bearer ${token}` : null
}

function handleError(err) {
  if (err.response) {
    const errorMsg = err.response.data.message;
    if (errorMsg) throw new Error(err.response.data.message);
    throw new Error(`Something went wrong. Error code: ${err.request.status}.`);
  }
  throw new Error("Something went wrong.");
}

export const fetchData = (url) =>
  axios
    .get(url)
    .then((res) => res.data)
    .catch(handleError);

export const postData = (url, data) =>
  axios
    .post(url, data)
    .then((res) => res.data)
    .catch(handleError);

export const updateData = (url, data) =>
  axios
    .put(url, data)
    .then((res) => res.data)
    .catch(handleError);

export const deleteData = (url, data) =>
  axios.delete(url, { data }).catch(handleError);


// create the image with a src of the base64 string
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

export const getCroppedImg = async (imageSrc, crop, aspect, resolution) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  // const maxWidth = 1280
  // const maxHeight = 720
  // let ratio = aspect

  if (resolution) {
    canvas.width = resolution[0]
    canvas.height = resolution[1]

  } else {
    canvas.width = image.width
    canvas.height = image.height

    /* setting canvas width & height allows us to
    resize from the original image resolution */
    // Check if the current width is larger than the max
    // if(image.width > maxWidth){
    //   ratio = maxWidth / image.width;   // get ratio for scaling image
    //   canvas.height = image.height * ratio;    // Reset height to match scaled image
    //   canvas.width = image.width * ratio;    // Reset width to match scaled image
    // }
    // // Check if current height is larger than max
    // if(image.height > maxHeight){
    //   ratio = maxHeight / image.height; // get ratio for scaling image
    //   canvas.width = image.width * ratio;    // Reset width to match scaled image
    //   canvas.height = image.height * ratio;    // Reset height to match scaled image
    // }

  }

  ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      canvas.width,
      canvas.height
  )

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob)
    }, 'image/jpeg')
  })
}

export function getImageSize(file) {
  return new Promise (function (resolved, rejected) {
    var i = new Image()
    i.onload = function(){
      resolved([i.width, i.height])
    };
    i.src = file
  })
}
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const createMap = (array, prop = "id") =>
  new Map(array.map((item) => [item[prop], item]));

export function compareProp(a, b, prop = "position") {
  if (a[prop] < b[prop]) {
    return -1;
  }
  if (a[prop] > b[prop]) {
    return 1;
  }
  return 0;
}
