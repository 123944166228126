const configuration = {
  endpoints: {
    api: process.env.REACT_APP_API_URL || "https://api-dev.joinfreddie.us",
    site: process.env.REACT_APP_APP_URL || "https://dev.joinfreddie.us/"
  },
  theme: {
    palette: {
      primary: {
        main: "rgb(58, 82, 115)",
        contrastText: "#fff"
      },
      secondary: {
        main: "rgb(58, 82, 115)",
        contrastText: "#fff"
      }
    },
    overrides: {
      RaAppBar: {
        menuButton: {
          marginLeft: "-15px"
        },
        toolbar: {
          marginRight: "40px"
        }
      },
      RaList: {
        root: {
          maxWidth: "100%",
          flex: 0
        },
        main: {
          flex: 0,
          padding: "0 40px",
          width: "calc(100vw - 230px)",
          overflow: "hidden",
          boxSizing: "border-box"
        }
      },
      RaListToolbar: {
        toolbar: {
          width: "100%",
          padding: "0 40px !important",
          boxSizing: "border-box"
        }
      },
      RaToolbar: {
        toolbar: {
          backgroundColor: "#fff",
          border: "1px solid #E0E0E0"
        }
      },
      MuiFilledInput: {
        root: {
          backgroundColor: "#E0E0E0"
        }
      }
    }
  }
};

export default configuration;
