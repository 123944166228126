import * as colors from "./colors";
import { errorColor } from "./colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { useMemo } from "react";
// import { useMediaQuery } from "@material-ui/@core";

const overrides = () => {
  return {
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
    },
    MuiFilledInput: {
      underline: {
        borderRadius: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiFormHelperText: {
      root: {
        margin: 0,
        '&$error': {
          backgroundColor: colors.errorColor.main,
          color: "white",
          margin: 0,
          padding: "2px 8px",
        }
      },
      error: {}
    },
    MuiError: {
      backgroundColor: "red",
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      label: {
        fontFamily: "Montserrat, sans-serif",
        textTransform: "uppercase",
      },
    },
  };
};

const typography = () => {
  return {
    fontFamily: ["Montserrat, sans-serif", "Source Sans Pro, sans-serif"],
    h2: {
      fontFamily: "Source Sans Pro, sans-serif"
    },
    h3: {
      fontFamily: "Playfair Display, sans-serif",
    },
    h4: {
      fontFamily: "Playfair Display, sans-serif",
    },
    h6: {
      fontFamily: "Avenir, sans-serif"
    },
    body1: {
      fontFamily: "Avenir, sans-serif",
      // fontSize: 14,
    },
  };
};

function useAppTheme(customOverrides) {
  const prefersDarkMode = false;
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: prefersDarkMode ? colors.primaryDark : colors.primaryLight,
          error: errorColor,
          secondary: prefersDarkMode
            ? colors.secondaryDark
            : colors.secondaryLight,
          background: prefersDarkMode
            ? colors.backgroundDark
            : colors.backgroundLight,
        },
        overrides: {...overrides(prefersDarkMode), ...customOverrides},
        typography: typography(prefersDarkMode),
      }),
    [customOverrides, prefersDarkMode]
  );
}

export { colors, useAppTheme };
