import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    banner: {
        height: 500,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
    },
    avatarWrapper: {
        opacity: 1,
        position: "absolute",
        bottom: 20,
        right: 100,
    },
    actionsWrapper: {
        opacity: 1,
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        bottom: 20,
        left: 100,
    },
    avatar: {
        width: 100,
        height: 100,
        border: "5px solid white",
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    listTitle: {
        color: "#3A5273",
        fontSize: 18,
        fontWeight: "bold",
    },
    listItem: {
        margin: 16,
        fontFamily: "'Avenir', sans-serif",
    },
    feature: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    editable: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
}));

const containerVariants = {
    hidden: {
        opacity: 0,
        transform: "scale(1.1)",
    },
    visible: {
        opacity: 1,
        transform: "scale(1)",
        transition: "opacity 500ms, transform 300ms",
        position: "absolute",
        bottom: 20,
        right: 20,
    },
    exit: {
        opacity: 1,
        transform: "scale(1)",
    },
};

const containerVariants2 = {
    hidden: {
        opacity: 0,
        transform: "scale(1.1)",
    },
    visible: {
        opacity: 1,
        transform: "scale(1)",
        transition: "opacity 500ms, transform 300ms",
        position: "absolute",
        bottom: 20,
        left: 20,
    },
    exit: {
        opacity: 1,
        transform: "scale(1)",
    },
};

const containerVariants3 = {
    hidden: {
        opacity: 0,
        // transform: "scale(0.95)",
    },
    visible: {
        opacity: 1,
        // transform: "scale(1)",
        transition: "opacity 500ms, transform 100ms",
        width: "100%",
        height: "100%",
    },
    exit: {
        opacity: 1,
        // transform: "scale(0.95)",
    },
};

export {useStyles, containerVariants, containerVariants3, containerVariants2}
