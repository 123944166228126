import React from "react";
import DashboardWidget from "../boilerplate/DashboardWidget";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 180
  },
})
const Dashboard = () => {
  const classes = useStyles()
  return <Container maxWidth={"xl"} classes={classes.root}>
    <DashboardWidget />
  </Container>
};

export default Dashboard;
