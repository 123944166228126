const primaryLight = {
    main: "#000",
    contrastText: "#fff",
};

const secondaryLight = {
    main: "#3A5273",
    contrastText: "#fff",
};

const errorColor = {
    main: "rgba(255, 0, 0, 0.8)"
};

const primaryDark = {
    main: "#000",
    // contrastText: "#181D30"
};
const secondaryDark = {
    main: "#000",
    // contrastText: "#181D30"
};

const backgroundLight = {
    paper: "#fff",
    default: "#fff",
};
const backgroundDark = {
    // default: "#384354",
    paper: "#181818",
    default: "#121212",
};

const defaultColorDark = {
    main: "#121212",
    contrastText: "#fff",
};
const defaultColorLight = {
    main: "#FCFCFC",
    light: "#ffffff",
    dark: "#ececec",
    contrastText: "#3A415B",
};

export {
    primaryLight,
    secondaryLight,
    primaryDark,
    secondaryDark,
    backgroundDark,
    backgroundLight,
    defaultColorLight,
    defaultColorDark,
    errorColor
};
