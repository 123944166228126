import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Avatar, Box, Button, Divider, Paper} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DropDialog from "../../boilerplate/DropDialog";
import { formatBytes, getFileUrl } from "../../utils";

const useStyles = makeStyles({
  aside: {
    padding: 16,
    width: 350,
    minWidth: 250,
    marginLeft: 16,
    position: "sticky",
    top: 16,
    height: "max-content",
  },
  feature: {
    width: "100%",
    height: "200px",
    objectFit: "contain",
  },
  avatar: {
    width: 100,
    height: 100,
    border: "5px solid white",
  },
});

function Aside(props) {
  const classes = useStyles();
  const {
    newFeatureImage,
    setNewFeatureImage,
    setNewProfileImage,
    newProfileImage,
    featureImageId,
    profileImageId,
  } = props;

  const [openFeatureCrop, setOpenFeatureCrop] = useState(false);
  const [openProfileCrop, setOpenProfileCrop] = useState(false);

  const feature = useMemo(() => {
    if (newFeatureImage) {
      return newFeatureImage.preview;
    } else if (featureImageId) {
      return getFileUrl(featureImageId);
    }
    return null;
  }, [featureImageId, newFeatureImage]);

  const profile = useMemo(() => {
    if (newProfileImage) {
      return newProfileImage.preview;
    } else if (profileImageId) {
      return getFileUrl(profileImageId);
    }
    return null;
  }, [newProfileImage, profileImageId]);

  const acceptFeatureImage = useCallback(
    (image) => {
      const file = new File([image], "feature.png", { type: "image/jpeg" });
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
      setNewFeatureImage(file);
    },
    [setNewFeatureImage]
  );

  const acceptProfileImage = useCallback(
    (image) => {
      const file = new File([image], "profile.png", { type: "image/jpeg" });
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
      setNewProfileImage(file);
    },
    [setNewProfileImage]
  );

  return (
    <>
      <Paper className={classes.aside}>
        <Box mb={1}>
          {profile ? (
              <Avatar
                  alt={"Profile"}
                  src={profile}
                  className={classes.avatar}
              />
          ) : (
            <Skeleton variant={"rect"} className={classes.avatar} />
          )}
        </Box>
        <Button
          variant={"outlined"}
          color={"primary"}
          onClick={() => setOpenProfileCrop(true)}
        >
          Add profile image
        </Button>
        <Box mt={2} />
        <Divider />
        <Box mt={2} />
        <Box mb={1}>
          {feature ? (
            <img src={feature} alt={"Feature"} className={classes.feature} />
          ) : (
            <Skeleton variant={"rect"} className={classes.feature} />
          )}
        </Box>
        <Button
          variant={"outlined"}
          color={"primary"}
          onClick={() => setOpenFeatureCrop(true)}
        >
          Add feature image
        </Button>
        <Box mt={2} />
      </Paper>
      <DropDialog
        open={openFeatureCrop}
        handleClose={() => setOpenFeatureCrop(false)}
        setImage={acceptFeatureImage}
        aspect={16 / 9}
        resolution={[1280, 720]}
        title={"Crop Feature Image"}
      />
      <DropDialog
        open={openProfileCrop}
        handleClose={() => setOpenProfileCrop(false)}
        setImage={acceptProfileImage}
        aspect={1}
        resolution={[480, 480]}
        cropShape={"round"}
        title={"Crop Profile Image"}
      />
    </>
  );
}

export default Aside;
