import { ImageField } from "react-admin";
import React from "react";

export const PreviewImage = ({ record, source }) => {
  if (typeof record == "string") {
    // eslint-disable-next-line
    record = {
      [source]: `https://jfapp-dev-appdata-development.s3.amazonaws.com/${record}`
    };
  }
  return <ImageField record={record} source={source} />;
};
