import { makeStyles } from "@material-ui/core/styles";
import BaseDialog from "../boilerplate/BaseDialog"
import { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import Box from "@material-ui/core/Box";
import { useDropzone } from "react-dropzone";
import { getCroppedImg, getImageSize } from "../utils";
import PropTypes from "prop-types";
import { Slider, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: 400,
  },
  container: {
    border: "4px dashed black",
    height: "100%",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  slider: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: "0 16px",
  },
});

const ImageCropper = ({
  getBlob,
  inputImg,
  aspect,
  showGrid,
  cropShape,
  zoom,
  setZoom,
  resolution,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  /* onCropComplete() will occur each time the user modifies the cropped area,
  which isn't ideal. A better implementation would be getting the blob
  only when the user hits the submit button, but this works for now  */
  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(
      inputImg,
      croppedAreaPixels,
      aspect,
      resolution
    );
    getBlob(croppedImage);
  };

  return (
    /* need to have a parent with `position: relative`
    to prevent cropper taking up whole page */
    <div className="cropper">
      <Cropper
        image={inputImg}
        crop={crop}
        zoom={zoom}
        aspect={aspect}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        cropShape={cropShape}
        showGrid={showGrid}
      />
    </div>
  );
};

function DropDialog(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    setImage,
    aspect,
    resolution,
    title,
    cropShape,
    showGrid,
    autoAspect,
  } = props;
  const [blob, setBlob] = useState(null);
  const [inputImg, setInputImg] = useState("");
  const [zoom, setZoom] = useState(1);
  const [aspectOverride, setAspectOverride] = useState(0);

  useEffect(() => {
    if (open) {
      setBlob(null);
      setInputImg("");
    }
  }, [open]);

  const getBlob = (blob) => {
    // pass blob up from the ImageCropper component
    setBlob(blob);
  };

  useEffect(() => {
    if (inputImg)
      getImageSize(inputImg)
        .then((size) => {
          // const res = size[0] > size[1] ? [1920, 1080] : [720, 1280]
          setAspectOverride(size[0] / size[1]);
        })
        .catch((ex) => console.log(ex));
  }, [inputImg]);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    const reader = new FileReader();
    const file = acceptedFiles[0];

    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/*",
  });

  const onConfirm = () => {
    setImage(blob);
    handleClose();
  };

  const handleReset = () => {
    setInputImg(null);
  };

  const handleChange = (event, newValue) => {
    setZoom(newValue);
  };

  return (
    <BaseDialog
      onClose={handleClose}
      open={open}
      title={title}
      secondaryAction={inputImg && handleReset}
      primaryAction={onConfirm}
      secondaryActionTitle={"Reset"}
    >
      <Box className={classes.root}>
        {!inputImg && (
          <div {...getRootProps()} className={classes.container}>
            <input {...getInputProps()} />
            <Typography>
              Drag and drop an image, or click{" "}
              <span style={{ textDecoration: "underline" }}>here</span> to
              select files
            </Typography>
          </div>
        )}
        <Box>
          {inputImg && autoAspect && (
            <ImageCropper
              getBlob={getBlob}
              inputImg={inputImg}
              aspect={aspectOverride}
              cropShape={cropShape}
              showGrid={showGrid}
              zoom={zoom}
              setZoom={setZoom}
              // resolution={resolutionOverride}
            />
          )}
          {inputImg && !autoAspect && (
            <ImageCropper
              getBlob={getBlob}
              inputImg={inputImg}
              aspect={aspect}
              cropShape={cropShape}
              showGrid={showGrid}
              zoom={zoom}
              setZoom={setZoom}
              resolution={resolution}
            />
          )}
        </Box>
      </Box>
      {inputImg && (
        <Box bgcolor={"#fff"} className={classes.slider}>
          <Slider
            step={0.1}
            min={1}
            max={5}
            value={zoom}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
          />
        </Box>
      )}
    </BaseDialog>
  );
}

export default DropDialog;

DropDialog.defaultProps = {
  aspect: 16 / 9,
  autoAspect: false,
  title: "Crop Image",
  showGrid: true,
  cropShape: "rect",
};
DropDialog.propTypes = {
  cropShape: PropTypes.oneOf(["rect", "round"]),
  autoAspect: PropTypes.bool.isRequired,
  showGrid: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  aspect: PropTypes.number.isRequired,
};
