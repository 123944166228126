import { Layout as RaLayout } from "react-admin";
import { makeStyles } from "@material-ui/core";
import AppBar from "./AppBar";
import React from "react";

const useStyles = makeStyles({
  appFrame: {
    marginTop: 0
  }
});

const Layout = (props) => {
  const classes = useStyles();
  return (
    <RaLayout
      classes={{ appFrame: classes.appFrame }}
      {...props}
      appBar={AppBar}
    />
  );
};

export default Layout;
