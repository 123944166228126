import {
  List,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  Filter,
  required
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomizableDatagrid from "ra-customizable-datagrid";
import React from "react";

const useStyles = makeStyles({
  field: { width: "calc(50% - 15px)" },
  root: { padding: "15px 30px" },
  aside: {
    width: 250,
    padding: "0 15px"
  }
});

const toolbarOptions = [
  [{ font: [] }, { size: [] }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ script: "super" }, { script: "sub" }],
  [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
  [
    { list: "ordered" },
    { list: "bullet" },
    { indent: "-1" },
    { indent: "+1" }
  ],
  ["direction", { align: [] }],
  ["link", "image", "video", "formula"],
  ["clean"]
];

const PageForm = () => {
  const classes = useStyles();
  return (
    <Container
      classes={{
        root: classes.root
      }}
      maxWidth="lg"
    >
      <TextInput
        validate={[required()]}
        fullWidth
        variant="outlined"
        source="title"
      />
      <TextInput
        validate={[required()]}
        fullWidth
        variant="outlined"
        source="slug"
      />
      <RichTextInput
        style={{ height: 500 }}
        validate={[required()]}
        fullWidth
        source="body"
        toolbar={toolbarOptions}
      />
    </Container>
  );
};

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
  </Filter>
);

export const PageList = (props) => (
  <List filters={<PageFilter />} {...props}>
    <CustomizableDatagrid>
      <TextField source="title" />
      <TextField source="slug" />
      <EditButton basePath="/manage/pages" />
    </CustomizableDatagrid>
  </List>
);

export const PageEdit = (props) => (
  <Edit title="Edit a Page" {...props}>
    <SimpleForm>
      <PageForm />
    </SimpleForm>
  </Edit>
);

export const PageCreate = (props) => (
  <Create title="Create a Page" {...props}>
    <SimpleForm>
      <PageForm />
    </SimpleForm>
  </Create>
);
