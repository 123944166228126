import { useRecordContext } from "react-admin";
import { makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import {ErrorOutlined, WarningRounded} from "@material-ui/icons";

const useStyles = makeStyles({
  checkIcon: {
    fill: "#0F9F4F",
    marginLeft: 26
  },
  cancerIcon: {
    fill: "orange",
    marginLeft: 26
  },
  rejectIcon: {
    fill: "red",
    marginLeft: 26
  }
});
export const BooleanField = (props) => {
  const classes = useStyles(props);
  const record = useRecordContext(props);
  switch (record.approved) {
    case 1: return <CheckCircleIcon className={classes.checkIcon} />
    case 2: return <ErrorOutlined className={classes.rejectIcon}/>
    default: return <WarningRounded className={classes.cancerIcon} />
  }

};
