const RESOURCES = {
  designers: "manage/designers",
  registerDesigner: "account/register/designer",
  homeowners: "manage/home-owners",
  users: "manage/users",
  articles: "manage/articles",
  pages: "manage/pages",
  inspirations: "manage/inspirations",
  tags: "manage/tags",
  media: "manage/media",
  designStyles: "manage/design-styles",
  glossary: "manage/glossary",
  siteDesigners: "account/register/designer",
  designerMessages: "manage/designer-messages",
  rooms: "manage/rooms",
  onlineUsers :'manage/activity/users',
  topSearched :'manage/activity/top',
  activityStatistics :'manage/activity/statistics',
  activityStream :'manage/activity/stream',
  categories: 'manage/categories',
};

export default RESOURCES;
