import {
  List,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  Filter,
  EditButton,
  Datagrid
} from "react-admin";
import React from "react";

const TagFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
  </Filter>
);

const TagForm = () => <TextInput source="name" />;

export const TagList = (props) => (
  <List filters={<TagFilter />} {...props}>
    <Datagrid>
      <TextField source="name" />
      <EditButton icon={null} basePath="/manage/tags" />
    </Datagrid>
  </List>
);

export const TagEdit = (props) => (
  <Edit title="Edit a Tag" {...props}>
    <SimpleForm>
      <TagForm />
    </SimpleForm>
  </Edit>
);

export const TagCreate = (props) => (
  <Create title="Create a Tag" {...props}>
    <SimpleForm>
      <TagForm />
    </SimpleForm>
  </Create>
);
