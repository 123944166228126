/* eslint-disable */
import React, { isValidElement, Children, cloneElement, useCallback } from "react";
import {
  Checkbox,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Container,
  withStyles
} from "@material-ui/core";
import classnames from "classnames";
import {
  sanitizeListRestProps,
  useListContext,
  useVersion,
  DatagridHeaderCell,
  DatagridLoading,
  DatagridBody,
  useDatagridStyles
} from "react-admin";


const StylledContainer = withStyles(() => ({
  root: {
    maxWidth:"100vw",
    padding: "0",
    overflow: "scroll",
  }
}))(Container);

const StickyTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#fff",
    left: 0,
    position: "sticky",
    zIndex: 999
  },
  body: {
    backgroundColor: "#fff",
    left: 0,
    position: "sticky",
    zIndex: 999,
  }
}))(TableCell);


const DatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  selected,
  basePath
}) => {
  const stickyChildren = children.filter(({props})  => props?.sticky)
  const nonStickyChildren = children.filter(({props})  => !props?.sticky)
  return (
    <TableRow key={id}>
       <StickyTableCell style={{position: stickyChildren.length > 0 ? "sticky": "static" }}>
         <TableCell style={{borderBottom:"none"}} padding="none">
                <Checkbox
                  checked={selected}
                  onClick={() => onToggleItem(id)}
                />
              </TableCell>
       {Children.map(stickyChildren, (field) => {
          return (
              <TableCell style={{borderBottom:"none"}} key={`${id}-${field?.props?.source}`}>
                {cloneElement(field, {
                  record,
                  basePath,
                  resource
                })}
              </TableCell>
            );
        })}
       </StickyTableCell>

      {Children.map(nonStickyChildren, (field) => {
        return (
          <TableCell key={`${id}-${field?.props?.source}`}>
            {cloneElement(field, {
              record,
              basePath,
              resource
            })}
          </TableCell>
        );
      })}
    </TableRow>
  );
};


const CustomDatagridBody = (props) => {
  return <DatagridBody {...props} row={<DatagridRow />} />;
};

const Datagrid = (props) => {
  const classes = useDatagridStyles(props);
  const {
    body = <CustomDatagridBody />,
    children,
    classes: classesOverride,
    className,
    expand,
    hasBulkActions = false,
    hover,
    isRowSelectable,
    rowClick,
    rowStyle,
    size = "small",
    ...rest
  } = props;

  const {
    basePath,
    currentSort,
    data,
    ids,
    loaded,
    onSelect,
    onToggleItem,
    resource,
    selectedIds,
    setSort,
    total
  } = useListContext(props);
  const version = useVersion();

  const updateSort = useCallback(
    (event) => {
      event.stopPropagation();
      const newField = event.currentTarget.dataset.field;
      const newOrder =
        currentSort.field === newField
          ? currentSort.order === "ASC"
            ? "DESC"
            : "ASC"
          : event.currentTarget.dataset.order;

      setSort(newField, newOrder);
    },
    [currentSort.field, currentSort.order, setSort]
  );

  const handleSelectAll = useCallback(
    (event) => {
      if (event.target.checked) {
        const all = ids.concat(selectedIds.filter((id) => !ids.includes(id)));
        onSelect(
          isRowSelectable ? all.filter((id) => isRowSelectable(data[id])) : all
        );
      } else {
        onSelect([]);
      }
    },
    [data, ids, onSelect, isRowSelectable, selectedIds]
  );


  if (loaded === false) {
    return (
      <DatagridLoading
        classes={classes}
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={Children.count(children)}
        size={size}
      />
    );
  }


  if (loaded && (ids.length === 0 || total === 0)) {
    return null;
  }

  const all = isRowSelectable
    ? ids.filter((id) => isRowSelectable(data[id]))
    : ids;

    const stickyChildren = children.filter(({props})  => props?.sticky)
    const nonStickyChildren = children.filter(({props})  => !props?.sticky)

  return (
    <StylledContainer>
    <Table
      className={classnames(classes.table, className)}
      size={size}
      {...sanitizeListRestProps(rest)}
    >
      <TableHead className={classes.thead}>
        <TableRow className={classnames(classes.row, classes.headerRow)}>
          {expand && (
            <TableCell
              padding="none"
              className={classnames(classes.headerCell, classes.expandHeader)}
            />
          )}
         <StickyTableCell style={{position: stickyChildren.length > 0 ? "sticky": "static" }}>
          {hasBulkActions && (
              <TableCell style={{borderBottom:"none"}} padding="checkbox" className={classes.headerCell}>
              <Checkbox
                className="select-all"
                color="primary"
                checked={
                  selectedIds.length > 0 &&
                  all.length > 0 &&
                  all.every((id) => selectedIds.includes(id))
                }
                onChange={handleSelectAll}
              />
            </TableCell>
          )}
     
          {Children.map(stickyChildren, (field, index) => {
              return (
              <DatagridHeaderCell
                style={{borderBottom:"none"}}
                className={classes.headerCell}
                currentSort={currentSort}
                field={field}
                isSorting={
                  currentSort.field ===
                  (field.props.sortBy || field.props.source)
                }
                key={field.props.source || index}
                resource={resource}
                updateSort={updateSort}
              />
          )
          }
          )}
          </StickyTableCell>
          
          {Children.map(nonStickyChildren, (field, index) => {
            return (
            isValidElement(field) ? (
              <DatagridHeaderCell
                className={classes.headerCell}
                currentSort={currentSort}
                field={field}
                isSorting={
                  currentSort.field ===
                  (field.props.sortBy || field.props.source)
                }
                key={field.props.source || index}
                resource={resource}
                updateSort={updateSort}
              />
            ) : null
            )
          }
          )}
        </TableRow>
      </TableHead>
      {cloneElement(
        body,
        {
          basePath,
          className: classes.tbody,
          classes,
          expand,
          rowClick,
          data,
          hasBulkActions,
          hover,
          ids,
          onToggleItem,
          resource,
          rowStyle,
          selectedIds,
          isRowSelectable,
          version
        },
        children
      )}
    </Table>
    </StylledContainer>
  );
};

export default Datagrid;
