import {
  List,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  Filter,
  EditButton,
  Datagrid
} from "react-admin";
import React from "react";

const GlossaryFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
  </Filter>
);

const GlossaryForm = () => (
  <>
    <TextInput fullWidth source="term" />
    <TextInput fullWidth source="description" />
  </>
);

export const GlossaryList = (props) => (
  <List filters={<GlossaryFilter />} {...props}>
    <Datagrid>
      <TextField source="term" />
      <TextField source="description" />
      <EditButton icon={null} basePath="/manage/glossary" />
    </Datagrid>
  </List>
);

export const GlossaryEdit = (props) => (
  <Edit title="Edit a Glossary" {...props}>
    <SimpleForm>
      <GlossaryForm />
    </SimpleForm>
  </Edit>
);

export const GlossaryCreate = (props) => (
  <Create title="Create a Glossary" {...props}>
    <SimpleForm>
      <GlossaryForm />
    </SimpleForm>
  </Create>
);
