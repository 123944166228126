import configuration from "../config";
import axios from "axios";
import { getJwt } from "../utils";

const authProvider = {
  login: ({ email, password }) => {
    const {
      endpoints: { api }
    } = configuration;
    const request = new Request(`${api}/account/login`, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        localStorage.setItem("authUser", JSON.stringify(response));
        localStorage.setItem("permissions", JSON.stringify(response)?.permissions);
        axios.defaults.headers.common = {
          Authorization: getJwt(),
        };
      });
  },
  logout: () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("permissions");
    return Promise.resolve();
  },
  checkAuth: () => {
    return JSON.parse(localStorage.getItem("authUser"))?.access_token || JSON.parse(sessionStorage.getItem("authUser"))?.access_token
      ? Promise.resolve()
      : Promise.reject();
  },
  checkError: () => Promise.resolve(),
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
  getIdentity: () =>
    Promise.resolve({
      id: "user",
      fullName: "Admin"
    })
};

export default authProvider;
