import {
  List,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EmailField,
  TextInput,
  EditButton,
  Filter,
  PasswordInput, Toolbar, SaveButton
} from "react-admin";
import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomizableDatagrid from "ra-customizable-datagrid";
import React from "react";
import Box from "@material-ui/core/Box";
import DeleteButtonWithConfirmation from "../boilerplate/DeleteButtonWithConfirmation";

const useStyles = makeStyles({
  field: { width: "calc(50% - 15px)" },
  root: { padding: "15px 30px" }
});

const UserForm = () => {
  const classes = useStyles();
  return (
    <Container
      classes={{
        root: classes.root
      }}
      maxWidth="lg"
    >
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        spacing={2}
      >
        <TextInput
          className={classes.field}
          variant="outlined"
          source="firstName"
        />
        <TextInput
          className={classes.field}
          variant="outlined"
          source="lastName"
        />
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        spacing={2}
      >
        <PasswordInput
          className={classes.field}
          variant="outlined"
          source="password"
        />
        <TextInput
          className={classes.field}
          variant="outlined"
          source="email"
        />
      </Grid>
    </Container>
  );
};

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
  </Filter>
);

export const UserList = (props) => (
  <List filters={<UserFilter />} {...props}>
    <CustomizableDatagrid>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <EditButton basePath="/manage/users" />
    </CustomizableDatagrid>
  </List>
);

export const UserEdit = (props) => (
  <Edit title="Edit a User" {...props}>
    <SimpleForm toolbar={
      <Toolbar>
        <SaveButton submitOnEnter={true} />
        <Box style={{ flex: 1 }} />
        <DeleteButtonWithConfirmation entity={"User"} />
      </Toolbar>
    }>
      <UserForm />
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props) => (
  <Create title="Create a User" {...props}>
    <SimpleForm>
      <UserForm />
    </SimpleForm>
  </Create>
);
