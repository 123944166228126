import React from "react";
import {
    Datagrid, DateField,
    Edit,
    EditButton,
    Filter,
    List,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
} from "react-admin";

const DesignerMessageFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
    <SelectInput
      alwaysOn
      initialValue={"pending"}
      choices={[
        {
          id: "pending",
          name: "Pending",
        },
        {
          id: "approved",
          name: "Approved",
        },
        {
          id: "rejected",
          name: "Rejected",
        },
      ]}
      label={"Status"}
      source="status"
    />
  </Filter>
);

const DesignerMessageForm = () => {
  return (
    <>
      <SelectInput
        choices={[
          {
            id: 0,
            name: "Pending",
          },
          {
            id: 1,
            name: "Approved",
          },
          {
            id: 2,
            name: "Rejected",
          },
        ]}
        fullWidth
        variant="outlined"
        source="status"
      />
      <TextInput fullWidth source="subject" />
      <TextInput fullWidth source="body" multiline />
    </>
  );
};

export const DesignerMessageList = (props) => {
  return (
    <List empty={false} filters={<DesignerMessageFilter />} {...props}>
      <Datagrid>
        <TextField source="designerFirstName" />
        <TextField source="designerLastName" />
        <TextField source="senderFirstName" />
        <TextField source="senderLastName" />
        <TextField source="senderEmail" />
        <TextField source="subject" />
        <DateField showTime source="createdAt" />
        <EditButton icon={null} basePath="/manage/designer-messages" />
      </Datagrid>
    </List>
  );
};

export const DesignerMessageEdit = (props) => (
  <Edit title="Edit a Designer Message" {...props}>
    <SimpleForm>
      <DesignerMessageForm />
    </SimpleForm>
  </Edit>
);
