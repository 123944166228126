import React, { useCallback, useEffect, useState, Fragment } from "react";
import {
  BooleanInput,
  Create,
  Edit,
  EditButton,
  EmailField,
  Filter,
  FunctionField,
  List,
  PasswordInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useMutation,
  DateField,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import {
  Avatar,
  Button,
  Container,
  Grid,
  IconButton,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomizableDatagrid from "../../shared/Datagrid";
import { BooleanField } from "../../shared/BooleanField";
import { Title } from "../../shared/Title";
import configuration from "../../config";
import { fetchData, formatBytes, getFileUrl, postData } from "../../utils";
import DesignerView from "./DesignerView";
import Box from "@material-ui/core/Box";
import Portfolio from "./Portfolio";
import Header from "./Header";
import Aside from "./Aside";
import RESOURCES from "../../boilerplate/resources";
import { useFormState } from "react-final-form";
import StackGrid, { transitions } from "react-stack-grid";
import DropDialog from "../../boilerplate/DropDialog";
import {
  DeleteOutline,
  InsertPhoto,
  MailOutline,
} from "@material-ui/icons";
import { useAppTheme } from "../../pageBuilder/theme";
import DeleteButtonWithConfirmation from "../../boilerplate/DeleteButtonWithConfirmation";
import { IconButtonField } from "../../shared/IconButtonField";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { fade } from "@material-ui/core/styles/colorManipulator";

const { scaleDown } = transitions;

const useStyles = makeStyles((theme) => ({
  field: { width: "calc(50% - 15px)" },
  select: { width: "calc(33% - 15px)" },
  layout: {
    height: "500px",
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  placeholderPhoto: {
    backgroundSize: "contain !important",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat !important",
    width: 250,
    height: 200,
    cursor: "pointer",
    "&:hover": {
      boxShadow: "inset 2000px 0 0 0 rgba(0, 0, 0, 0.5)",
    },
  },
  aside: {
    width: 400,
    padding: "0 15px",
  },
  title: {
    marginTop: -33,
    backgroundColor: "#fff",
    width: "max-content",
    margin: "auto",
    padding: 16,
    marginBottom: 16,
    fontSize: 24,
  },
  root: { padding: "15px", marginBottom: 20 },
  cover: {
    position: "relative",
    width: "100%",
    height: "100%",
    marginBottom: 45,
  },
  avatar: {
    opacity: 1,
    position: "absolute",
    bottom: 20,
    right: 50,
    width: 100,
    border: "5px solid white",
    height: 100,
    borderRadius: "50%",
    display: "flex",
    overflow: "hidden",
    fontSize: "1.25rem",
    alignItems: "center",
    flexShrink: 0,
    lineHeight: 1,
    userSelect: "none",
    justifyContent: "center",
  },
  avatarImg: {
    color: "transparent",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    textAlign: "center",
    textIndent: "10000px",
    "&:hover": {
      background: "#f00",
    },
  },
  heading: {
    fontSize: 15,
    fontWeight: 500,
  },
  carousel: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
  },
  accordion: {
    marginBottom: 25,
  },
  checkIcon: {
    fill: "#0F9F4F",
    marginLeft: 15,
  },
  cancerIcon: {
    fill: "#CECECE",
    marginLeft: 26,
  },
  delete: { cursor: "pointer" },
  formContainer: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  toolbar: {
    margin: 0,
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textAlign: "center",
    padding: "32px",
  },
  dialogContent: {
    padding: "32",
  },
  dialogButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const { api } = configuration.endpoints;

const DesignerForm = (props) => {
  const classes = useStyles();
  const {
    type,
    setFeatureImageId,
    setProfileImageId,
    setPortfolioImageIds,
    portfolioImageIds,
    setPortfolioImages,
    portfolioImages,
  } = props;

  const [openPortfolioCrop, setOpenPortfolioCrop] = useState(false);
  const [data, setData] = useState([]);
  const { values } = useFormState();

  useEffect(() => {
    fetchData(`${api}/account/register/designer`)
      .then((data) => setData(data))
      .catch((ex) => console.log(ex));
  }, []);

  useEffect(() => {
    setFeatureImageId(values?.featureImageId);
    setProfileImageId(values?.profileImageId);
    // TODO: Fix it
    setPortfolioImageIds(values?.portfolioImages?.map((x) => x.imageId));
  }, [
    setFeatureImageId,
    setProfileImageId,
    setPortfolioImageIds,
    values?.featureImageId,
    values?.profileImageId,
    values.portfolioImageIds,
    values?.portfolioImages,
  ]);

  const acceptPortfolioImage = useCallback(
    (image) => {
      const file = new File([image], "profile.png", { type: "image/jpeg" });
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
      setPortfolioImages((prevState) => [...prevState, file]);
    },
    [setPortfolioImages]
  );

  const removeImage = useCallback(
    (uuid) => {
      setPortfolioImageIds((prevState) => prevState.filter((x) => x !== uuid));
    },
    [setPortfolioImageIds]
  );

  const removeAddedImage = useCallback(
    (index) => {
      setPortfolioImages((prevState) => [
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1),
      ]);
    },
    [setPortfolioImages]
  );

  return (
    <>
      <Title style={{ marginTop: 25 }} title="GENERAL INFO" />
      <Container
        classes={{
          root: classes.root,
        }}
        maxWidth="lg"
      >
        <Grid container direction="row" spacing={2} justify="center">
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            spacing={2}
            validate={[required()]}
          >
            <TextInput
              className={classes.field}
              variant="outlined"
              source="firstName"
              validate={[required()]}
            />
            <TextInput
              className={classes.field}
              variant="outlined"
              source="lastName"
              validate={[required()]}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            spacing={2}
            validate={[required()]}
          >
            <TextInput
              className={classes.field}
              variant="outlined"
              source="email"
              validate={[required()]}
            />
            <TextInput
              className={classes.field}
              variant="outlined"
              source="instagram"
            />
          </Grid>

          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            spacing={2}
          ></Grid>

          {type === "create" && (
            <PasswordInput
              rows="10"
              fullWidth
              variant="outlined"
              source="password"
            />
          )}

          <SelectInput
            choices={data.professionalCategories}
            fullWidth
            variant="outlined"
            source="professionalCategoryId"
            validate={[required()]}
          />

          <TextInput
            rows="10"
            multiline
            fullWidth
            variant="outlined"
            source="bio"
          />
        </Grid>
      </Container>
      <Title title="BUSINESS INFO" />
      <Container
        classes={{
          root: classes.root,
        }}
        maxWidth="lg"
      >
        <Grid container direction="row" spacing={2} justify="center">
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            spacing={2}
          >
            <TextInput
              className={classes.field}
              variant="outlined"
              source="businessName"
              validate={[required()]}
            />
            <TextInput
              className={classes.field}
              variant="outlined"
              source="businessWebSite"
            />
            <TextInput
              className={classes.field}
              variant="outlined"
              source="businessEstablished"
            />
            <TextInput
              className={classes.field}
              variant="outlined"
              label="Practicing Professional Since"
              source="since"
            />
          </Grid>
          <SelectInput
            choices={data.businessTypes}
            fullWidth
            variant="outlined"
            source="businessTypeId"
          />

          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            spacing={2}
          >
            <SelectInput
              className={classes.field}
              choices={data.employeeRanges}
              fullWidth
              variant="outlined"
              source="employeesRangeId"
            />
            <TextInput
              className={classes.field}
              fullWidth
              variant="outlined"
              type={"number"}
              source="phoneNumber"
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            spacing={2}
          >
            <TextInput
              className={classes.field}
              variant="outlined"
              source="streetAddress"
            />
            <TextInput
              className={classes.field}
              variant="outlined"
              source="streetAddress2"
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            spacing={2}
          >
            <TextInput
              className={classes.select}
              variant="outlined"
              source="city"
              validate={[required()]}
            />
            <SelectInput
              className={classes.select}
              choices={data.states}
              variant="outlined"
              source="stateId"
              validate={[required()]}
            />
            <TextInput
              className={classes.select}
              variant="outlined"
              source="zipCode"
              validate={[required()]}
            />
          </Grid>
        </Grid>
      </Container>
      <Title title="STYLING INFO" />
      <Container
        classes={{
          root: classes.root,
        }}
        maxWidth="lg"
      >
        <Grid container direction="row" spacing={2} justify="center">
          <TextInput fullWidth variant="outlined" source="associations" />
          <SelectInput
            fullWidth
            choices={data.consultations}
            variant="outlined"
            source="consultationId"
            validate={[required()]}
          />
          <SelectInput
            fullWidth
            choices={data.minimumProjectSpends}
            variant="outlined"
            source="minimumProjectSpendId"
          />
          <SelectInput
            fullWidth
            choices={data.averageFurnishingsSpends}
            variant="outlined"
            source="averageFurnishingsSpendId"
          />
          <SelectInput
            fullWidth
            choices={data.averageFabricWallpaperSpends}
            variant="outlined"
            source="averageFabricWallpaperSpendId"
          />
          <SelectInput
            fullWidth
            choices={data.averageCarpetsRugsSpends}
            variant="outlined"
            source="averageCarpetsRugsSpendId"
          />
          <SelectArrayInput
            fullWidth
            choices={data.specialties}
            variant="outlined"
            source="specialtyIds"
            validate={[required()]}
          />
          <SelectArrayInput
            fullWidth
            choices={data.designStyles}
            variant="outlined"
            source="designStyleIds"
            validate={[required()]}
          />
          <SelectArrayInput
            fullWidth
            choices={data.adviceOffers}
            variant="outlined"
            source="adviceOfferIds"
          />
          <SelectInput
            choices={[
              {
                id: "A",
                name: "A",
              },
              {
                id: "B",
                name: "B",
              },
              {
                id: "C",
                name: "C",
              },
              {
                id: "D",
                name: "D",
              },
            ]}
            fullWidth
            variant="outlined"
            source="designerClass"
          />
          {values.id && (
            <TextInput
              fullWidth
              variant="outlined"
              source="slug"
              validate={[required()]}
            />
          )}
        </Grid>
      </Container>

      <Title title="TRIGGERS" />
      <Container
        classes={{
          root: classes.root,
        }}
        maxWidth="lg"
      >
        <SelectInput
          choices={[
            {
              id: 0,
              name: "Pending",
            },
            {
              id: 1,
              name: "Approved",
            },
            {
              id: 2,
              name: "Rejected",
            },
          ]}
          fullWidth
          variant="outlined"
          source="approved"
        />
        <BooleanInput source="public" />
        <BooleanInput source="optInDirectMail" />
        <BooleanInput source="optInNewsletter" />
        <BooleanInput source="homepage" />
        <BooleanInput source="monetize" />
        <BooleanInput source="promote" />
      </Container>

      <Title title="PORTFOLIO" />
      <Container
        classes={{
          root: classes.root,
        }}
        maxWidth="lg"
      >
        <Button
          startIcon={<InsertPhoto />}
          variant={"outlined"}
          color={"primary"}
          onClick={() => setOpenPortfolioCrop(true)}
        >
          New Portfolio Image
        </Button>
        <Box mt={2} />

        <StackGrid
          gutterWidth={20}
          gutterHeight={20}
          duration={500}
          appear={scaleDown.appear}
          appeared={scaleDown.appeared}
          enter={scaleDown.enter}
          monitorImagesLoaded={true}
          entered={scaleDown.entered}
          leaved={scaleDown.leaved}
          columnWidth={"33%"}
        >
          {portfolioImageIds?.map((uuid, index) => (
            <div
              style={{ minHeight: "max-content", position: "relative" }}
              key={index}
            >
              <IconButton
                onClick={() => removeImage(uuid)}
                style={{ position: "absolute", top: 0, right: 0, margin: 8 }}
              >
                <DeleteOutline style={{ fill: "red" }} />
              </IconButton>
              <img
                style={{ maxWidth: "100%" }}
                src={getFileUrl(uuid)}
                alt={uuid}
              />
            </div>
          ))}
          {portfolioImages?.map((file, index) => (
            <div
              style={{ minHeight: "max-content", position: "relative" }}
              key={index}
            >
              <IconButton
                onClick={() => removeAddedImage(index)}
                style={{ position: "absolute", top: 0, right: 0, margin: 8 }}
              >
                <DeleteOutline style={{ fill: "red" }} />
              </IconButton>
              <img
                style={{ maxWidth: "100%" }}
                src={file.preview}
                alt={"portfolio"}
              />
            </div>
          ))}
        </StackGrid>
      </Container>
      <DropDialog
        open={openPortfolioCrop}
        handleClose={() => setOpenPortfolioCrop(false)}
        setImage={acceptPortfolioImage}
        autoAspect
        title={"Crop Portfolio Image"}
      />
    </>
  );
};

const DesignerFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
    <SelectInput
      alwaysOn
      initialValue={"pending"}
      choices={[
        {
          id: "pending",
          name: "Pending",
        },
        {
          id: "approved",
          name: "Approved",
        },
        {
          id: "rejected",
          name: "Rejected",
        },
      ]}
      label={"Status"}
      source="status"
    />
  </Filter>
);

const designerImage = (id) => {
  if (id) {
    return getFileUrl(id);
  }
  return null;
};

export const DesignerList = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [email, setEmail] = useState(null);

  const handleClick = (designer) => {
    setEmail(designer.email);
    setOpenDialog(true);
  };

  return (
    <Fragment>
      <List empty={false} filters={<DesignerFilter />} {...props}>
        <CustomizableDatagrid>
          <FunctionField
            sticky
            label="Avatar"
            render={(record) => (
              <Avatar src={designerImage(record.profileImageId)} />
            )}
          />
          <BooleanField sticky source="approved" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="slug" />
          <EmailField source="email" />
          <IconButtonField icon={<MailOutline />} onClick={handleClick} />
          <TextField source="businessName" />
          <TextField source="consultation" />
          <TextField source="city" />
          <TextField source="state" />
          <TextField source="zipCode" />
          <DateField source="createdAt" />
          <DateField source="acceptedAt" />
          <EditButton icon={null} basePath="/manage/designers" />
          <ShowButton icon={null} basePath="/manage/designers" />
        </CustomizableDatagrid>
      </List>
      <ConfirmationEmail
        handleClose={() => setOpenDialog(false)}
        open={openDialog}
        email={email}
      />
    </Fragment>
  );
};

function ConfirmationEmail({ handleClose, open, email }) {
  const classes = useStyles();
  const [busy, setBusy] = useState(false);

  const handleConfirm = () => {
    const url = `${api}/account/resend-confirmation`;
    setBusy(true);
    postData(url, { email })
      .then(() => {
        setBusy(false);
        handleClose();
      })
      .catch((ex) => {
        setBusy(false);
        console.log(ex);
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={handleClose}
      aria-label="Are you sure?"
    >
      <DialogTitle className={classes.dialogTitle}>
        Send Confirmation
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box p={4}>
          <Typography variant={"body1"}>
            You are about to resent a confirmation to {email}.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          disabled={busy}
          color={"primary"}
          variant={"outlined"}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const DesignerEdit = (props) => {
  const classes = useStyles();
  const {
    setNewProfileImage,
    profileImageId,
    setNewFeatureImage,
    setProfileImageId,
    newProfileImage,
    setFeatureImageId,
    featureImageId,
    newFeatureImage,
    setPortfolioImageIds,
    portfolioImageIds,
    portfolioImages,
    setPortfolioImages,
    save,
  } = useDesignerState();
  return (
    <Edit
      title="Edit a Designer"
      aside={
        <Aside
          setNewProfileImage={setNewProfileImage}
          setNewFeatureImage={setNewFeatureImage}
          newProfileImage={newProfileImage}
          newFeatureImage={newFeatureImage}
          profileImageId={profileImageId}
          featureImageId={featureImageId}
        />
      }
      {...props}
    >
      <SimpleForm
        className={classes.formContainer}
        toolbar={
          <Toolbar alwaysEnableSaveButton className={classes.toolbar}>
            <SaveButton submitOnEnter={true} />
            <Box style={{ flex: 1 }} />
            <DeleteButtonWithConfirmation entity={"Designer"} />
          </Toolbar>
        }
        save={save}
        redirect="edit"
      >
        <DesignerForm
          type="edit"
          setProfileImageId={setProfileImageId}
          setFeatureImageId={setFeatureImageId}
          setPortfolioImageIds={setPortfolioImageIds}
          portfolioImageIds={portfolioImageIds}
          portfolioImages={portfolioImages}
          setPortfolioImages={setPortfolioImages}
        />
      </SimpleForm>
    </Edit>
  );
};

export const DesignerCreate = (props) => {
  const classes = useStyles();
  const {
    setNewProfileImage,
    profileImageId,
    setNewFeatureImage,
    setProfileImageId,
    newProfileImage,
    setFeatureImageId,
    featureImageId,
    newFeatureImage,
    setPortfolioImageIds,
    portfolioImageIds,
    portfolioImages,
    setPortfolioImages,
    save,
  } = useDesignerState();

  return (
    <Create
      title="Create a Designer"
      aside={
        <Aside
          setNewProfileImage={setNewProfileImage}
          setNewFeatureImage={setNewFeatureImage}
          newProfileImage={newProfileImage}
          newFeatureImage={newFeatureImage}
          profileImageId={profileImageId}
          featureImageId={featureImageId}
        />
      }
      {...props}
    >
      <SimpleForm
        className={classes.formContainer}
        save={save}
        toolbar={<Toolbar className={classes.toolbar} />}
        redirect="create"
      >
        <DesignerForm
          type="create"
          setProfileImageId={setProfileImageId}
          setFeatureImageId={setFeatureImageId}
          setPortfolioImageIds={setPortfolioImageIds}
          portfolioImageIds={portfolioImageIds}
          portfolioImages={portfolioImages}
          setPortfolioImages={setPortfolioImages}
        />
      </SimpleForm>
    </Create>
  );
};

const useStyles2 = makeStyles({
  root: {
    // position: "fixed",
    width: "100%",
    margin: "16px 0",
  },
  stickyItem: {
    // position: "sticky",
    // top: 120,
  },
});

const designerViewModel = {
  firstName: "",
  lastName: "",
  email: "",
  profileImageId: "",
  featureImageId: "",
  consultation: "",
  businessWebSite: "",
  instagram: null,
  bio: null,
  since: null,
  specialties: [],
  designStyles: [],
  adviceOffers: [],
  portfolioImageIds: [],
  portfolioImages: [],
};

export const DesignerShow = (props) => {
  const [designer, setDesigner] = useState(designerViewModel);
  const [loading, setLoading] = useState(false);
  const id = props.match.params.id;

  useEffect(() => {
    setLoading(true);
    fetchData(`${api}/manage/designers/${id}/preview`)
      .then((data) => {
        setDesigner(data);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
      });
  }, [id]);

  const shouldCollapsePortfolio = designer?.portfolioImageIds?.length === 0;

  const classes = useStyles2();
  const theme = useAppTheme();
  return (
    <Show title="View a Designer" {...props}>
      <MuiThemeProvider theme={theme}>
        <Container maxWidth={"lg"}>
          <div className={classes.root}>
            <Header
              isLoading={loading}
              featureImage={designer.featureImageId}
              profileImage={designer.profileImageId}
            />
            <Box mt={4} />
            <Grid container spacing={4} alignItems={"flex-start"}>
              <Grid item md={6} sm={12} xs={12}>
                <Portfolio
                  shouldCollapsePortfolio={shouldCollapsePortfolio}
                  isLoading={loading}
                  portfolioImageIds={designer.portfolioImageIds}
                />
              </Grid>
              <Grid
                item
                md={shouldCollapsePortfolio ? 12 : 6}
                sm={12}
                className={classes.stickyItem}
              >
                <DesignerView
                  shouldCollapsePortfolio={shouldCollapsePortfolio}
                  designer={designer}
                  isLoading={loading}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </MuiThemeProvider>
    </Show>
  );
};

const useDesignerState = () => {
  const [newFeatureImage, setNewFeatureImage] = useState(null);
  const [featureImageId, setFeatureImageId] = useState(null);
  const [newProfileImage, setNewProfileImage] = useState(null);
  const [profileImageId, setProfileImageId] = useState(null);
  const [portfolioImages, setPortfolioImages] = useState([]);
  const [portfolioImageIds, setPortfolioImageIds] = useState([]);

  const [mutate] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const save = useCallback(
    async (values) => {
      try {
        const result = await mutate(
          {
            type: values.id ? "update" : "create",
            resource: RESOURCES.designers,
            payload: {
              data: values,
              newFeatureImage,
              newProfileImage,
              portfolioImageIds,
              portfolioImages,
            },
          },
          { returnPromise: true }
        );
        setPortfolioImages([]);
        if (values.id) {
          refresh();
          notify("Designer Updated");
        } else {
          redirect("/manage/designers/" + result.data.id);
          notify("Designer Saved");
        }
      } catch (ex) {
        return "Something went wrong";
      }
    },
    [
      mutate,
      newFeatureImage,
      newProfileImage,
      portfolioImageIds,
      portfolioImages,
      refresh,
      notify,
      redirect,
    ]
  );

  return {
    save,
    newFeatureImage,
    featureImageId,
    setNewFeatureImage,
    setFeatureImageId,
    newProfileImage,
    profileImageId,
    setProfileImageId,
    setNewProfileImage,
    portfolioImages,
    setPortfolioImages,
    portfolioImageIds,
    setPortfolioImageIds,
  };
};
