import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Create,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useMutation,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  ThemeProvider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomizableDatagrid from "../shared/Datagrid";
import { useFormState } from "react-final-form";
import MediaLibraryDialog from "../shared/MediaLibraryDialog";
import { useAppTheme } from "../pageBuilder/theme";
import configuration from "../config";
import { Editor, initState } from "gk-page-builder";
import DropDialog from "../boilerplate/DropDialog";
import { Skeleton } from "@material-ui/lab";
import Categories from "./Categories";
import { formatBytes } from "../utils";
import RESOURCES from "../boilerplate/resources";
import { Prompt } from "react-router";

const useStyles = makeStyles({
  appBar: {
    position: "relative",
  },
  paper: {
    position: "absolute",
    width: "90vw",
    height: "90vh",
    overflow: "scroll",
    backgroundColor: "white",
    border: "2px solid #000",
    borderRadius: 5,
    padding: 10,
  },
  field: { width: "calc(50% - 15px)" },
  root: { padding: "15px 30px", maxWidth: "1280px" },
  aside: {
    padding: 16,
    width: 350,
    minWidth: 250,
    marginLeft: 16,
    position: "sticky",
    top: 16,
    height: "max-content",
  },
  feature: {
    width: "100%",
    height: "200px",
    objectFit: "fill",
  },
  placeholderPhoto: {
    backgroundSize: "contain !important",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: 250,
    height: 200,
    cursor: "pointer",
    "&:hover": {
      boxShadow: "inset 2000px 0 0 0 rgba(0, 0, 0, 0.5)",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  toolbar: {
    margin: 0,
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
  },
});

function loadState(blockString) {
  try {
    const stored = JSON.parse(blockString);
    return initState(stored);
  } catch (e) {
    return [];
  }
}

const ArticleForm = ({
  state,
  setState,
  setCategoryIds,
  setCoverId,
  isEditing,
  setRecord,
}) => {
  const classes = useStyles();
  const { values } = useFormState();
  const [openLibrary, setOpenLibrary] = useState(false);
  const [image, setImage] = useState("");
  const freddieTheme = useAppTheme();
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    setRecord(values);
  }, [setRecord, values]);

  useEffect(() => {
    setCategoryIds(values?.categories?.map((x) => x.id) || []);
  }, [setCategoryIds, values?.categories]);

  useEffect(() => {
    setCoverId(values?.imageId || null);
  }, [setCoverId, values?.imageId]);

  useEffect(() => {
    const newState = loadState(values?.body);
    setState(newState);
    setDirty(false)
  }, [setState, values?.body]);

  const handleUpdateState = useCallback((newState) => {
    setState(newState)
    setDirty(true)
  }, [setState])

  const handleAcceptMedia = (src) => {
    setImage(src);
    setOpenLibrary(false);
  };

  return (
    <Container
      classes={{
        root: classes.root,
      }}
      maxWidth="lg"
    >
      <Prompt
        when={dirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <TextInput fullWidth variant="outlined" source="title" />
      <TextInput fullWidth variant="outlined" source="slug" />
      <TextInput fullWidth variant="outlined" source="summary" multiline />

      <ThemeProvider theme={freddieTheme}>
        <Box my={isEditing ? 4 : 1}>
          <Editor
            state={state}
            setState={handleUpdateState}
            isEditing={isEditing}
            mediaSourceUpdate={image}
            clearSourceUpdate={() => setImage("")}
            onSelectMedia={() => setOpenLibrary(true)}
          />
        </Box>
      </ThemeProvider>

      {/*<ImageInput*/}
      {/*  fullWidth*/}
      {/*  source="imageId"*/}
      {/*  label="Featured image"*/}
      {/*  accept="image/*"*/}
      {/*>*/}
      {/*  <PreviewImage source="imageId" title="title" />*/}
      {/*</ImageInput>*/}
      <MediaLibraryDialog
        open={openLibrary}
        handleClose={() => setOpenLibrary(false)}
        handleConfirm={handleAcceptMedia}
      />
    </Container>
  );
};

const ArticleFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
  </Filter>
);

export const ArticleList = (props) => (
  <List filters={<ArticleFilter />} {...props}>
    <CustomizableDatagrid>
      <TextField source="title" />
      <TextField source="slug" />
      <TextField source="summary" />
      <EditButton basePath="/manage/articles" />
    </CustomizableDatagrid>
  </List>
);

const Aside = ({
  categoryIds,
  setCategoryIds,
  newCover,
  setNewCover,
  coverId,
}) => {
  // const dataProvider = useDataProvider()
  const {
    endpoints: { api },
  } = configuration;

  const classes = useStyles();
  const [openFeatureCrop, setOpenFeatureCrop] = useState(false);

  const displayImage = useMemo(() => {
    if (newCover) {
      return newCover.preview;
    } else if (coverId) {
      return `${api}/files/${coverId}`;
    }
    return null;
  }, [api, coverId, newCover]);

  const acceptFeatureImage = useCallback(
    (image) => {
      const file = new File([image], "feature.png", { type: "image/jpeg" });
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
      setNewCover(file);
    },
    [setNewCover]
  );

  return (
    <>
      <Paper className={classes.aside}>
        <Box mb={1}>
          {displayImage ? (
            <img
              src={displayImage}
              alt={"Feature"}
              className={classes.feature}
            />
          ) : (
            <Skeleton variant={"rect"} className={classes.feature} />
          )}
        </Box>
        <Button
          variant={"outlined"}
          color={"primary"}
          onClick={() => setOpenFeatureCrop(true)}
        >
          Add feature image
        </Button>
        <Box mt={2} />
        <Divider />
        <Box mt={2} />
        <Categories selectedIds={categoryIds} setSelectedIds={setCategoryIds} />
      </Paper>
      <DropDialog
        open={openFeatureCrop}
        handleClose={() => setOpenFeatureCrop(false)}
        setImage={acceptFeatureImage}
        aspect={16 / 9}
        resolution={[1280, 720]}
        title={"Crop Feature Image"}
      />
    </>
  );
};

const ApproveButton = ({ record }) => {
  const [mutate, { loading }] = useMutation();
  const refresh = useRefresh();
  const notify = useNotify();

  const onApprove = useCallback(async () => {
    try {
      await mutate(
        {
          type: "update",
          resource: "/manage/articles/publish",
          payload: { id: record.id },
        },
        { returnPromise: true }
      );
      refresh();
      notify("Article Published", "success");
    } catch (ex) {
      return "Something went wrong";
    }
  }, [mutate, notify, record.id, refresh]);
  return record.id ? (
    <Button
      color={"primary"}
      variant={"contained"}
      onClick={onApprove}
      disabled={loading || !record.dirty}
    >
      Publish
    </Button>
  ) : null;
};

const PostCreateToolbar = (props) => (
  <Toolbar {...props} className={props.className}>
    <Button
      variant="outlined"
      color="primary"
      onClick={() => props.setIsEditing(!props.isEditing)}
    >
      {props.isEditing ? "Preview" : "Edit Page"}
    </Button>
    <Box ml={1} />
    <SaveButton variant="outlined" label="Save Draft" submitOnEnter={true} />
    <Box ml={1} />
    <ApproveButton record={props.record} />
    <Box ml={"auto"} />
    <DeleteButton label={"Delete"} undoable={false} />
  </Toolbar>
);

export const ArticleEdit = (props) => {
  const {
    categoryIds,
    setCategoryIds,
    save,
    setState,
    state,
    newCover,
    setNewCover,
    coverId,
    setCoverId,
  } = useArticleState();

  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [record, setRecord] = useState({});

  return (
    <Edit
      aside={
        <Aside
          coverId={coverId}
          categoryIds={categoryIds}
          setCategoryIds={setCategoryIds}
          newCover={newCover}
          setNewCover={setNewCover}
        />
      }
      title="Edit a Article"
      {...props}
    >
      <SimpleForm
        className={classes.formContainer}
        save={save}
        toolbar={
          <PostCreateToolbar
            className={classes.toolbar}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            record={record}
          />
        }
        submitOnEnter={false}
      >
        <ArticleForm
          setCoverId={setCoverId}
          setCategoryIds={setCategoryIds}
          state={state}
          setRecord={setRecord}
          isEditing={isEditing}
          setState={setState}
        />
      </SimpleForm>
    </Edit>
  );
};

export const ArticleCreate = (props) => {
  const {
    categoryIds,
    setCategoryIds,
    save,
    setState,
    state,
    newCover,
    setNewCover,
    coverId,
    setCoverId,
  } = useArticleState();
  const [isEditing, setIsEditing] = useState(false);
  const [record, setRecord] = useState({});

  const classes = useStyles();

  return (
    <Create
      aside={
        <Aside
          coverId={coverId}
          categoryIds={categoryIds}
          setCategoryIds={setCategoryIds}
          newCover={newCover}
          setNewCover={setNewCover}
        />
      }
      title="Create a Article"
      {...props}
      submitOnEnter={false}
    >
      <SimpleForm
        className={classes.formContainer}
        save={save}
        toolbar={
          <PostCreateToolbar
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            record={record}
          />
        }
      >
        <ArticleForm
          setCoverId={setCoverId}
          setCategoryIds={setCategoryIds}
          setState={setState}
          state={state}
          setRecord={setRecord}
          isEditing={isEditing}
        />
      </SimpleForm>
    </Create>
  );
};

const useArticleState = () => {
  const [state, setState] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [newCover, setNewCover] = useState(null);
  const [coverId, setCoverId] = useState(null);

  const [mutate] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const save = useCallback(
    async (values) => {
      try {
        const result = await mutate(
          {
            type: values.id ? "update" : "create",
            resource: RESOURCES.articles,
            payload: {
              data: { ...values, categoryIds, body: JSON.stringify(state) },
              newCover,
            },
          },
          { returnPromise: true }
        );
        if (values.id) {
          refresh();
          notify("Article Updated");
        } else {
          redirect("/manage/articles/" + result.data.id);
          notify("Article Saved");
        }
      } catch (ex) {
        return "Something went wrong";
      }
    },
    [mutate, categoryIds, state, newCover, refresh, redirect, notify]
  );

  return {
    save,
    state,
    setState,
    categoryIds,
    setCategoryIds,
    newCover,
    setNewCover,
    coverId,
    setCoverId,
  };
};
