import {Fragment} from 'react'
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import StackGrid, { transitions } from "react-stack-grid";
import { getFileUrl } from "../../utils";
import React from "react";
import {makeStyles} from "@material-ui/styles";

const { scaleDown } = transitions;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
}))

const Portfolio = (props) => {
  const classes = useStyles();

  const {
    shouldCollapsePortfolio,
    isLoading,
    portfolioImageIds,
  } = props;

  if (shouldCollapsePortfolio) return null;
    return (
      <Fragment>
        <div className={classes.root} style={{ height: "100%" }}>
          {isLoading ? (
            <Grid container spacing={4}>
              {[...Array(5)].map((k, index) => (
                <Grid item sm={index === 0 ? 12 : 6} xs={12}>
                  <Skeleton variant="rect" height={400} width={"100%"} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid xs={12}>
              <StackGrid
                style={{ minHeight: 500 }}
                gutterWidth={20}
                gutterHeight={20}
                duration={500}
                appear={scaleDown.appear}
                appeared={scaleDown.appeared}
                enter={scaleDown.enter}
                monitorImagesLoaded={true}
                entered={scaleDown.entered}
                leaved={scaleDown.leaved}
                columnWidth={portfolioImageIds.length > 1 ? "50%" : "100%"}
              >
                {portfolioImageIds.map((uuid, index) => (
                  <div style={{ minHeight: "max-content" }} key={index}>
                    <img
                      style={{ maxWidth: "100%" }}
                      src={getFileUrl(uuid)}
                      alt={uuid}
                    />
                  </div>
                ))}
              </StackGrid>
            </Grid>
          )}
        </div>
      </Fragment>
    );
};

export default Portfolio;
