import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import {motion} from "framer-motion";
import {containerVariants, containerVariants3, useStyles,} from "./styles";
import {Avatar,} from "@material-ui/core";
import Placeholder from "../../boilerplate/Placeholder";
import {getFileUrl} from "../../utils";

function Header(props) {
  const classes = useStyles();
  const {
    isLoading,
    featureImage,
    profileImage,
  } = props;

  return (
    <div className={classes.banner}>
      {isLoading ? (
        <Skeleton variant="rect" className={classes.feature} />
      ) : (
        <motion.div
          style={{ flex: 1 }}
          className="wrapper"
          variants={containerVariants3}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {!featureImage ? (
            <Placeholder type={"Designer"} />
          ) : (
            <img
              src={getFileUrl(featureImage)}
              alt={"Feature"}
              className={classes.feature}
            />
          )}
        </motion.div>
      )}
      <motion.div
        className={classes.avatarWrapper}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {isLoading || !profileImage ? (
          <Skeleton variant="circle" className={classes.avatar} />
        ) : (
          <div>
            <div>
              <Avatar
                alt={"Profile"}
                src={getFileUrl(profileImage)}
                className={classes.avatar}
              />
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default Header;
