import { ListBase, useMutation } from "react-admin";
import {
  Box,
  GridList,
  GridListTile,
  GridListTileBar,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Dropzone from "react-dropzone";
import React, { useCallback, useState } from "react";
import configuration from "../config";
import RESOURCES from "../boilerplate/resources";
import { useAdminState } from "../hooks";
import { fetchData } from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    overflow: "hidden",
  },
  dropStyles: {
    flex: 1,
    display: "flex",
    height: 300,
    flexDirection: "column",
    alignItems: "center",
    padding: "50px 20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    color: "#bdbdbd",
    outline: "none",
    cursor: "pointer",
    transition: "border .24s ease-in-out",
  },
  placeholderPhoto: {
    backgroundSize: "contain !important",
    backgroundRepeat: "no-repeat",
    width: 250,
    height: 200,
    cursor: "pointer",
    "&:hover": {
      boxShadow: "inset 2000px 0 0 0 rgba(0, 0, 0, 0.5)",
    },
  },
  gridList: {
    width: "100%",
    height: "auto",
  },
  titleBar: {
    height: 32,
  },
  delete: {
    cursor: "pointer",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const { api } = configuration.endpoints;

export const MediaList = (props) => {
  const classes = useStyles();
  const images = useAdminState();
  const [, setImages] = useState([]);
  const [mutate] = useMutation();
  const [value, setValue] = useState(0);
  const slugValue = Math.random().toString().substr(2, 8);

  const onChange = (event, newValue) => setValue(newValue);

  const onDrop = useCallback(
    (file) => {
      const data = {
        pictures: file,
        slug: slugValue,
      };
      mutate(
        {
          type: "create",
          resource: RESOURCES.media,
          payload: { data },
        },
        {
          onSuccess: (response) => {
            fetchData(`${api}/manage/media`)
              .then((data) => setImages(data))
              .catch((ex) => console.log(ex));
          },
          onFailure: (error) => {},
        }
      );
    },
    [mutate, slugValue]
  );

  const onDelete = (id) =>
    mutate({
      type: "delete",
      resource: RESOURCES.media,
      payload: { id },
    });

  return (
    <ListBase className={classes.root} {...props}>
      <Tabs value={value} onChange={onChange}>
        <Tab label="Library" {...a11yProps(0)} />
        <Tab label="Upload image" {...a11yProps(1)} />
      </Tabs>
      <TabPanel style={{ marginLeft: "-21px" }} value={value} index={0}>
        <GridList cols={5} className={classes.gridList}>
          {images?.map(({ slug, id }) => (
            <GridListTile key={slug}>
              {/* eslint-disable-next-line */}
              <div
                style={{
                  background: `url(${api}/media/${slug})`,
                }}
                className={classes.placeholderPhoto}
              />
              <GridListTileBar
                className={classes.titleBar}
                subtitle={
                  <DeleteIcon
                    className={classes.delete}
                    onClick={() => onDelete(id)}
                  />
                }
              />
            </GridListTile>
          ))}
        </GridList>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <section className={classes.dropStyles}>
              <div className="container">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Typography>
                    Drag and drop an image, or click{" "}
                    <span style={{ textDecoration: "underline" }}>here</span> to
                    select files
                  </Typography>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </TabPanel>
    </ListBase>
  );
};
