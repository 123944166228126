import {
  List,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  Filter,
  EditButton,
  Datagrid,
  NumberInput,
  ImageInput
} from "react-admin";
import { PreviewImage } from "../shared/PreviewImage";
import React from "react";

const DesignStyleFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
  </Filter>
);

const DesignStyleForm = () => (
  <>
    <TextInput fullWidth source="name" />
    <NumberInput fullWidth source="order" />
    <ImageInput
      fullWidth
      source="imageId"
      label="Related pictures"
      accept="image/*"
    >
      <PreviewImage source="imageId" title="title" />
    </ImageInput>
  </>
);

export const DesignStyleList = (props) => (
  <List filters={<DesignStyleFilter />} {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="order" />
      <EditButton icon={null} basePath="/manage/design-styles" />
    </Datagrid>
  </List>
);

export const DesignStyleEdit = (props) => (
  <Edit title="Edit a DesignStyle" {...props}>
    <SimpleForm>
      <DesignStyleForm />
    </SimpleForm>
  </Edit>
);

export const DesignStyleCreate = (props) => {
  return (
    <Create title="Create a DesignStyle" {...props}>
      <SimpleForm>
        <DesignStyleForm />
      </SimpleForm>
    </Create>
  );
};
