import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    marginTop: -33,
    backgroundColor: "#fff",
    width: "max-content",
    margin: "auto",
    padding: 16,
    marginBottom: 16,
    fontSize: 24,
  },
});

function SectionTitle({ title, ...rest }) {
  const classes = useStyles();
  return (
    <div>
      <Divider style={{ backgroundColor: "#000" }} />
      <Typography variant={"h4"} className={classes.title} {...rest}>
        {title}
      </Typography>
    </div>
  );
}

export default SectionTitle;
