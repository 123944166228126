import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import configuration from "../config";
import {
  compareProp,
  deleteData,
  fetchData,
  postData,
  updateData,
} from "../utils";
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";
import {
  AddCircleOutline,
  DeleteOutline,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import BaseDialog from "../boilerplate/BaseDialog";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 360,
    overflow: "auto",
    margin: "16px 0",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function Categories({ selectedIds, setSelectedIds }) {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const {
    grouped,
    createCategory,
    updateCategory,
    deleteCategory,
    busy,
    error,
    setError,
  } = useCategories(selectedIds, setSelectedIds, setOpen);

  const isSelected = (id) => selectedIds.includes(id);
  const handleChange = (id) => {
    setSelectedIds((prevstate) => {
      const checked = prevstate.includes(id);
      if (checked) {
        return prevstate.filter((x) => x !== id);
      }
      return [...prevstate, id];
    });
  };

  const handleCategoryClick = (category) => {
    setError("");
    setSelectedCategory(category);
    setOpen(true);
  };

  const handleNewClick = (category) => {
    setError("");
    setSelectedCategory({
      parentId: category.id,
      previewName: category.name,
    });
    setOpen(true);
  };

  return (
    <div>
      <TextField
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        variant={"filled"}
        label={"Search for Category"}
        fullWidth
      />
      <List
        className={classes.root}
        component="nav"
        aria-label="main mailbox folders"
      >
        {grouped
          .filter((x) => x.name.toLowerCase().includes(query.toLowerCase()))
          .map((category) => (
            <CategoryRow
              category={category}
              handleChange={handleChange}
              isSelected={isSelected}
              onClick={handleCategoryClick}
              onNewClick={handleNewClick}
            />
          ))}
      </List>
      <Button
        variant={"outlined"}
        color={"primary"}
        onClick={() => {
          setSelectedCategory(null);
          setOpen(true);
        }}
        endIcon={<AddCircleOutline />}
      >
        Create Category
      </Button>
      <CategoryDialog
        open={open}
        errorMessage={error}
        busy={busy}
        category={selectedCategory}
        handleClose={() => setOpen(false)}
        deleteCategory={deleteCategory}
        updateCategory={updateCategory}
        createCategory={createCategory}
      />
    </div>
  );
}

function CategoryRow({
  category,
  onClick,
  onNewClick,
  isSelected,
  handleChange,
}) {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <ListItem button key={category.id} onClick={() => onClick(category)}>
        <ListItemIcon onClick={(e) => e.stopPropagation()}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected(category.id)}
                onChange={() => handleChange(category.id)}
                name="checked"
              />
            }
            label={""}
          />
        </ListItemIcon>
        <ListItemText primary={category.name} secondary={category.slug} />
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onNewClick(category);
          }}
        >
          <AddCircleOutline />
        </IconButton>
        <IconButton
          disabled={category.children.length === 0}
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItem>
      <Collapse
        in={expanded && category.children.length > 0}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {category.children.map((subCategory) => (
            <ListItem button onClick={() => onClick(subCategory)}>
              <ListItemIcon onClick={(e) => e.stopPropagation()}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSelected(subCategory.id)}
                      onChange={() => handleChange(subCategory.id)}
                      name="checked"
                    />
                  }
                  label={""}
                />
              </ListItemIcon>
              <ListItemText
                primary={subCategory.name}
                secondary={subCategory.slug}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}
const { api } = configuration.endpoints;
const url = `${api}/manage/categories`;

const useCategories = (selectedIds, setSelectedIds, setOpenDialog) => {
  const [categories, setCategories] = useState([]);
  const [busy, setBusy] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      fetchData(url)
        .then((data) => setCategories(data))
        .catch((ex) => console.log(ex));
    })();
  }, []);

  const grouped = useMemo(() => {
    const root = categories.filter((x) => !x.parentId);
    const children = categories.filter((x) => !!x.parentId);

    return root
      .map((r) => ({
        ...r,
        position: selectedIds.includes(r.id) ? 0 : 1,
        children: children.filter((x) => x.parentId === r.id),
      }))
      .sort((a, b) => compareProp(a, b, "name"))
      .sort(compareProp);
  }, [categories, selectedIds]);

  const createCategory = async (payload) => {
    setBusy(true);
    try {
      const result = await postData(url, payload);
      setCategories((prevState) => [...prevState, result]);
      setSelectedIds((prevIds) => [...prevIds, result.id]);
      setOpenDialog(false);
    } catch (ex) {
      setError(ex.message);
    }
    setBusy(false);
  };

  const updateCategory = async (payload) => {
    setBusy(true);
    try {
      const result = await updateData(`${url}/${payload.id}`, payload);
      setCategories((prevState) =>
        prevState.map((cat) => {
          if (cat.id === result.id) {
            return result;
          }
          return cat;
        })
      );
      setOpenDialog(false);
    } catch (ex) {
      setError(ex.message);
    }
    setBusy(false);
  };

  const deleteCategory = async (payload) => {
    setBusy(true);
    try {
      await deleteData(`${url}/${payload.id}`, payload);
      setCategories((prevState) =>
        prevState.filter((x) => x.id !== payload.id)
      );
      setSelectedIds((preIds) => preIds.filter((id) => id !== payload.id));
      setOpenDialog(false);
    } catch (ex) {
      setError(ex.message);
    }
    setBusy(false);
  };

  return {
    categories,
    grouped,
    createCategory,
    updateCategory,
    deleteCategory,
    busy,
    error,
    setError,
  };
};

function CategoryDialog(props) {
  const {
    handleClose,
    open,
    category,
    createCategory,
    updateCategory,
    deleteCategory,
    busy,
    errorMessage,
  } = props;
  const [form, setForm] = useState({
    name: "",
    slug: "",
  });

  useEffect(() => {
    setForm({
      name: category?.name || "",
      slug: category?.slug || "",
      parentId: category?.parentId || null,
    });
  }, [category]);

  const handleChange = (prop) => (e) => {
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [prop]: value,
    }));
  };

  const payload = {
    ...category,
    ...form,
  };

  const handleConfirm = payload.id ? updateCategory : createCategory;

  const title = payload.id
    ? `Edit ${category.name}`
    : payload.previewName
    ? `Add subcategory to ${payload.previewName}`
    : "Add Category";

  return (
    <BaseDialog
      onClose={handleClose}
      open={open}
      fullWidth
      primaryActionTitle={"Save"}
      inProgress={busy}
      maxWidth={"xs"}
      errorMessage={errorMessage}
      primaryAction={() => handleConfirm(payload)}
      title={title}
    >
      <TextField
        margin={"dense"}
        variant={"filled"}
        fullWidth
        label={"Name"}
        value={form.name}
        onChange={handleChange("name")}
      />
      <TextField
        margin={"dense"}
        variant={"filled"}
        fullWidth
        label={"Slug"}
        value={form.slug}
        onChange={handleChange("slug")}
      />
      {payload.id && (
        <Box mt={2}>
          <Button
            onClick={() => deleteCategory(payload)}
            disabled={busy}
            style={{ color: "red" }}
            startIcon={<DeleteOutline />}
          >
            Delete
          </Button>
        </Box>
      )}
    </BaseDialog>
  );
}
