import {
  List,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  Filter,
  EditButton,
  ImageInput
} from "react-admin";
import CustomizableDatagrid from "ra-customizable-datagrid";
import { PreviewImage } from "../shared/PreviewImage";
import React from "react";

const InspirationFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
  </Filter>
);

const InspirationForm = () => (
  <>
    <TextInput fullWidth source="title" />
    <TextInput fullWidth source="slug" />
    <ImageInput
      fullWidth
      source="imageId"
      label="Related pictures"
      accept="image/*"
    >
      <PreviewImage source="imageId" title="title" />
    </ImageInput>
  </>
);

export const InspirationList = (props) => (
  <List filters={<InspirationFilter />} {...props}>
    <CustomizableDatagrid>
      <TextField source="title" />
      <TextField source="slug" />
      <EditButton icon={null} basePath="/manage/inspirations" />
    </CustomizableDatagrid>
  </List>
);

export const InspirationEdit = (props) => (
  <Edit title="Edit a Inspiration" {...props}>
    <SimpleForm>
      <InspirationForm />
    </SimpleForm>
  </Edit>
);

export const InspirationCreate = (props) => (
  <Create title="Create a Inspiration" {...props}>
    <SimpleForm>
      <InspirationForm />
    </SimpleForm>
  </Create>
);
