import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Dialog,
  DialogActions,
  Box,
  Typography,
  Tab,
  Tabs, Grid, Card, CardActionArea, CardMedia
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types'
import axios from "axios";
import configuration from "../config";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    objectFit: "cover"
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{minHeight: 400}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const {
  endpoints: { api }
} = configuration;

function MediaLibraryDialog({open, handleClose, handleConfirm}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [media, setMedia] = useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("authUser"))?.access_token || JSON.parse(sessionStorage.getItem("authUser"))?.access_token
    if (value === 0) {
      axios.get(`${api}/manage/media`, {
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {
        if (res.status === 200 && res.data) {
          setMedia(res.data);
        }
      });
    }
  }, [value])

  return <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth={"lg"}
    fullWidth
  >
    <AppBar position="static">
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Library" {...a11yProps(0)} />
        <Tab label="Upload Image" {...a11yProps(1)} />
      </Tabs>
    </AppBar>
    <TabPanel value={value} index={0}>
      <Grid container spacing={2}>
        {
          media.map(({slug, fileName}) => {
            const url = `${api}/media/${slug}`
            return (
              <Grid item key={slug} sm={3}>
                <Card variant={"outlined"}>
                  <CardActionArea onClick={() => handleConfirm(url)}>
                    <CardMedia
                      className={classes.media}
                      image={url}
                      title={fileName}
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })
        }
      </Grid>
    </TabPanel>
    <TabPanel value={value} index={1}>
      Drop here
    </TabPanel>
    <DialogActions>
      <Button onClick={handleClose} color="primary" autoFocus>
        Close
      </Button>
    </DialogActions>
  </Dialog>
}

export default MediaLibraryDialog;
