import {
  Create,
  Edit,
  List,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  Filter,
  Datagrid
} from "react-admin";
import React from "react";

const RoomFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ width: 400 }} label="Search" source="q" alwaysOn />
  </Filter>
);

const RoomForm = () => (
  <>
    <TextInput fullWidth source="name" />
  </>
);

export const RoomList = (props) => (
  <List filters={<RoomFilter />} {...props}>
    <Datagrid>
      <TextField source="name" />
       <EditButton  icon={null} basePath="/manage/rooms" />
    </Datagrid>
  </List>
);

export const RoomEdit = (props) => (
  <Edit title="Edit a Room" {...props}>
    <SimpleForm>
      <RoomForm />
    </SimpleForm>
  </Edit>
);

export const RoomCreate = (props) => (
  <Create title="Create a Room" {...props}>
    <SimpleForm>
      <RoomForm />
    </SimpleForm>
  </Create>
);

